import React from 'react';
import QuestionsCarousel from './QuestionsCarousle';

const QuizItem = ({ quiz }) => {
  return (
    <div className="border rounded-lg bg-muted/20 border-dashed mb-2 sm:p-2">
      <h2 className="px-2 pt-1 font-bold text-xl text-center mb-1">
        {quiz.title}
      </h2>
      <div className="h-1 mx-auto bg-gray-200 w-28 opacity-75 rounded mb-2" />
      <QuestionsCarousel questions={quiz.questions} />
    </div>
  );
};

export default QuizItem;
