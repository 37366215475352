export const questions = {
  array: [
    {
      id: 1,
      qs: 'Factorial Of Large Numbers',
      link: 'https://practice.geeksforgeeks.org/problems/factorials-of-large-numbers2508/1?utm_source=gfg&utm_medium=article&utm_campaign=bottom_sticky_on_article',
      ytvid:
        'https://www.youtube.com/watch?v=O3fwYjcMV_M&list=PLpIkg8OmuX-K6A0sEPFxOSJh4_AjCGAFf&index=8',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Factorials%20of%20large%20numbers.cpp',
      'Company Tags':
        'ADOBE, BROWSERSTACK, MAKEMYTRIP, MAQ SOFTWARE, MICROSOFT, MORGAN STANLEY, PHILIPS, SAMSUNG',
    },
    {
      id: 2,
      qs: "Kadane's Algorithm (Maximum Subarray)",
      link: 'https://leetcode.com/problems/maximum-subarray/',
      ytvid: 'Will soon come',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Maximum_Subarray.cpp',
      'Company Tags':
        'VMWare, Zoho, Flipkart, Morgan Stanley, Accolite, Amazon, Microsoft, OYO Rooms, Samsung, Snapdeal, \n24*7 Innovation Labs, D-E-Shaw, FactSet, Hike, Housing.com, MakeMyTrip, MetLife, Ola Cabs, Oracle, Payu, Teradata, Visa, Walmart',
    },
    {
      id: 3,
      qs: 'Maximum Sum Circular Subarray',
      link: 'https://leetcode.com/problems/maximum-sum-circular-subarray/',
      ytvid: 'https://www.youtube.com/watch?v=Za8V4wkZKkM',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Maximum%20Sum%20Circular%20Subarray.cpp',
      'Company Tags': 'Amazon, Microsoft',
    },
    {
      id: 4,
      qs: 'Majority Element',
      link: 'https://leetcode.com/problems/majority-element/',
      ytvid: 'https://www.youtube.com/watch?v=Q6L5SoS-fTo',
      Git: "https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Majority%20Element%20(Moore's%20Voting).cpp",
      'Company Tags':
        'Amazon, Accolite, D-E-Shaw, FactSet, MakeMyTrip, Microsoft, Samsung',
    },
    {
      id: 5,
      qs: 'Majority Element II',
      link: 'https://leetcode.com/problems/majority-element-ii/',
      ytvid: 'https://www.youtube.com/watch?v=Q6L5SoS-fTo',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Majority%20Element%20II.cpp',
      'Company Tags': 'Amazon, Google',
    },
    {
      id: 6,
      qs: '3Sum',
      link: 'https://leetcode.com/problems/3sum/',
      ytvid:
        'https://www.youtube.com/watch?v=_cBWWebTVpI&list=PLpIkg8OmuX-K6A0sEPFxOSJh4_AjCGAFf&index=5',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Two%20Pointer/3Sum.cpp',
      'Company Tags': 'Amazon, Facebook, Google',
    },
    {
      id: 7,
      qs: '3Sum Closest',
      link: 'https://leetcode.com/problems/3sum-closest/',
      ytvid: 'https://www.youtube.com/watch?v=hGLjwiPRh0U',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Two%20Pointer/3Sum%20Closest.cpp',
      'Company Tags': 'Facebook, Amazon, Microsoft, Oracle',
    },
    {
      id: 8,
      qs: 'Trapping Rain Water',
      link: 'https://leetcode.com/problems/trapping-rain-water/',
      ytvid:
        'https://www.youtube.com/watch?v=f2QgmVxPiS4&list=PLpIkg8OmuX-K6A0sEPFxOSJh4_AjCGAFf&index=4',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Trapping%20Rain%20Water%20(without%20stack).cpp',
      'Company Tags':
        'Accolite, Adobe, Amazon, D-E-Shaw, MakeMyTrip, Microsoft, Payu',
    },
    {
      id: 9,
      qs: 'Next Permutation',
      link: 'https://leetcode.com/problems/next-permutation/',
      ytvid:
        'https://www.youtube.com/watch?v=CboKBIpo640&list=PLpIkg8OmuX-K6A0sEPFxOSJh4_AjCGAFf&index=7',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Next%20Permutation.cpp',
      'Company Tags':
        'Amazon, FactSet, Hike, Amazon, MakeMyTrip, Qualcomm, Infosys, Microsoft, Google, Salesforce, Flipkart',
    },
    {
      id: 10,
      qs: 'Sort Colors',
      link: 'https://leetcode.com/problems/sort-colors/',
      ytvid:
        'https://www.youtube.com/watch?v=KDiZ3jGXxO8&list=PLpIkg8OmuX-K6A0sEPFxOSJh4_AjCGAFf&index=15',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Sort%20Colors.cpp',
      'Company Tags':
        'Adobe, Amazon, Hike, MakeMyTrip, MAQ Software, Microsoft, Morgan Stanley, Ola Cabs, OYO Rooms, Paytm, Qualcomm, \nSamsung, SAP Labs, Snapdeal, Walmart, Yatra.com, Flipkart',
    },
    {
      id: 11,
      qs: 'Median of Two Sorted Arrays',
      link: 'https://leetcode.com/problems/median-of-two-sorted-arrays/',
      ytvid: 'https://www.youtube.com/watch?v=6D9T2ZY8h5c',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/edit/master/Arrays/Binary%20Search/Median%20of%20Two%20Sorted%20Arrays.cpp',
      'Company Tags':
        'Google, Microsoft, Apple, Zenefits, Yahoo, Adobe, Dropbox',
    },
    {
      id: 12,
      qs: 'Arithmetic Subarrays',
      link: 'https://leetcode.com/problems/arithmetic-subarrays',
      ytvid: 'https://www.youtube.com/watch?v=ErHay1GfIqw',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Arithmetic%20Subarrays.cpp',
      'Company Tags': 'Atlassian Online Assessment',
    },
  ],
  interval: [
    {
      id: 1,
      qs: 'Maximum Number of Events That Can Be Attended II',
      link: 'https://leetcode.com/problems/maximum-number-of-events-that-can-be-attended/',
      ytvid: 'will soon come',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Intervals_Based_Qn/Maximum%20Number%20of%20Events%20That%20Can%20Be%20Attended%20II.cpp',
      'Company Tags': 'Amazon Online Assessment',
    },
    {
      id: 2,
      qs: 'Data Stream as Disjoint Intervals',
      link: 'https://leetcode.com/problems/data-stream-as-disjoint-intervals/',
      ytvid: 'https://www.youtube.com/watch?v=Lh0PXb3Hqik',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Intervals_Based_Qn/Data%20Stream%20as%20Disjoint%20Intervals.cpp',
      'Company Tags': 'Amazon',
    },
    {
      id: 3,
      qs: 'Insert Interval',
      link: 'https://leetcode.com/problems/insert-interval/',
      ytvid: 'https://www.youtube.com/watch?v=XXaVIDIbUn4',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Intervals_Based_Qn/Insert%20Interval.cpp',
      'Company Tags':
        'Google Online Round (2021), Twitter, Microsoft, Apple, Amazon',
    },
    {
      id: 4,
      qs: 'Interval List Intersections',
      link: 'https://leetcode.com/problems/interval-list-intersections/',
      ytvid: 'will soon come',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Intervals_Based_Qn/Interval%20List%20Intersections.cpp',
      'Company Tags': 'Amazon, Meta, Microsoft',
    },
    {
      id: 5,
      qs: 'Meeting Rooms III',
      link: 'https://leetcode.com/problems/meeting-rooms-iii/description/',
      ytvid: 'https://www.youtube.com/watch?v=8JTr7Hk0p2o',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Intervals_Based_Qn/Meeting%20Rooms%20III.cpp',
      'Company Tags': 'Google',
    },
  ],
  binarySearch: [
    {
      id: 1,
      qs: 'Search in Rotated Sorted Array',
      link: 'https://leetcode.com/problems/search-in-rotated-sorted-array/',
      ytvid: 'https://www.youtube.com/watch?v=U1VsdRgVevY',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Search%20in%20Rotated%20Sorted%20Array.cpp',
      'Company Tags':
        'Google, Adobe, Amazon, Microsoft, Morgan Stanley, Samsung, Snapdeal, Times Internet',
    },
    {
      id: 2,
      qs: 'Search in Rotated Sorted Array II',
      link: 'https://leetcode.com/problems/search-in-rotated-sorted-array-ii/',
      ytvid: 'https://www.youtube.com/watch?v=eneo_XPUEj0',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Search%20in%20Rotated%20Sorted%20Array%20II.cpp',
      'Company Tags':
        'Adobe, Amazon, Microsoft, Morgan Stanley, Samsung, Snapdeal, Times Internet',
    },
    {
      id: 3,
      qs: 'Single Element in a Sorted Array',
      link: 'https://leetcode.com/problems/single-element-in-a-sorted-array/',
      ytvid: 'https://www.youtube.com/watch?v=cw5XhYeAU5o',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Single%20Element%20in%20a%20Sorted%20Array.cpp',
      'Company Tags':
        'Amazon, D-E-Shaw, Ola Cabs, Codenation, Qualcomm, Microsoft',
    },
    {
      id: 4,
      qs: 'Last Day Where You Can Still Cross',
      link: 'https://leetcode.com/problems/last-day-where-you-can-still-cross/',
      ytvid:
        'https://www.youtube.com/watch?v=U4C7-PF_fi8&list=PLpIkg8OmuX-LkgtrEF7eyyYWJM3m5tVQY&index=17',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Last%20Day%20Where%20You%20Can%20Still%20Cross.cpp',
      'Company Tags': 'GOOGLE (on site interview), META',
    },
    {
      id: 5,
      qs: 'Koko Eating Bananas',
      link: 'https://leetcode.com/problems/koko-eating-bananas/',
      ytvid:
        'https://www.youtube.com/watch?v=QQcEIxK-snE&list=PLpIkg8OmuX-LkgtrEF7eyyYWJM3m5tVQY&index=11',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Koko%20Eating%20Bananas.cpp',
      'Company Tags': 'Google, Miscrosoft',
    },
    {
      id: 6,
      qs: 'Search a 2D Matrix',
      link: 'https://leetcode.com/problems/search-a-2d-matrix/',
      ytvid:
        'https://www.youtube.com/watch?v=NYk3IuJinuc&list=PLpIkg8OmuX-LkgtrEF7eyyYWJM3m5tVQY&index=3',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Search%20a%202D%20Matrix.cpp',
      'Company Tags':
        'Accolite, MentorGraphics, Adobe, Amazon, Directi, Goldman Sachs, Groupon, InMobi, MakeMyTrip, Ola Cabs, One97, Oracle, Paytm, Polycom, SAP Labs, Snapdeal, TinyOwl, Visa, Microsoft',
    },
  ],
  bsOnAns: [
    {
      id: 1,
      qs: 'Koko Eating Bananas',
      link: 'https://leetcode.com/problems/koko-eating-bananas/',
      ytvid:
        'https://www.youtube.com/watch?v=QQcEIxK-snE&list=PLpIkg8OmuX-LkgtrEF7eyyYWJM3m5tVQY&index=11',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Koko%20Eating%20Bananas.cpp',
      'Company Tags': 'Google, Miscrosoft',
      'What you will learn from this ??': '',
    },
    {
      id: 2,
      qs: 'Minimum Speed to Arrive on Time',
      link: 'https://leetcode.com/problems/minimum-speed-to-arrive-on-time/',
      ytvid:
        'https://www.youtube.com/watch?v=6VHDJMYtn3Q&list=PLpIkg8OmuX-LkgtrEF7eyyYWJM3m5tVQY&index=19',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Minimum%20Speed%20to%20Arrive%20on%20Time.cpp',
      'Company Tags': 'Google',
      'What you will learn from this ??': '',
    },
    {
      id: 3,
      qs: 'Capacity To Ship Packages Within D Days',
      link: 'https://leetcode.com/problems/capacity-to-ship-packages-within-d-days/',
      ytvid: 'Will soon come',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Capacity%20To%20Ship%20Packages%20Within%20D%20Days.cpp',
      'Company Tags': 'Meta, Baidu, Google, Flipkart, D-E-Shaw, Amazon',
      'What you will learn from this ??': '',
    },
    {
      id: 4,
      qs: 'Magnetic Force Between Two Balls',
      link: 'https://leetcode.com/problems/magnetic-force-between-two-balls/',
      ytvid: 'Will soon come',
      Git: 'Will soon come',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 5,
      qs: 'Minimum Number of Days to Make m Bouquets',
      link: 'https://leetcode.com/problems/minimum-number-of-days-to-make-m-bouquets/',
      ytvid: 'Will soon come',
      Git: 'Will soon come',
      'Company Tags': 'Google',
      'What you will learn from this ??': '',
    },
    {
      id: 6,
      qs: 'Median of Two Sorted Arrays',
      link: 'https://leetcode.com/problems/median-of-two-sorted-arrays/',
      ytvid: 'https://www.youtube.com/watch?v=7nABqJCEMuY',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/edit/master/Arrays/Binary%20Search/Median%20of%20Two%20Sorted%20Arrays.cpp',
      'Company Tags':
        'Google, Microsoft, Apple, Zenefits, Yahoo, Adobe, Dropbox',
      'What you will learn from this ??': '',
    },
    {
      id: 7,
      qs: 'Kth Missing Positive Number',
      link: 'https://leetcode.com/problems/kth-missing-positive-number/',
      ytvid:
        'https://www.youtube.com/watch?v=BltzCxN1vRc&list=PLpIkg8OmuX-LkgtrEF7eyyYWJM3m5tVQY&index=9',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Kth%20Missing%20Positive%20Number.cpp',
      'Company Tags': 'Google, Apple, Amazon, Meta',
      'What you will learn from this ??': '',
    },
    {
      id: 8,
      qs: 'Allocate minimum number of pages',
      link: 'https://practice.geeksforgeeks.org/problems/allocate-minimum-number-of-pages0937/1',
      ytvid: 'Will soon come',
      Git: 'Will soon come',
      'Company Tags': 'Infosys, Amazon, Microsoft, Google, Codenation',
      'What you will learn from this ??': '',
    },
    {
      id: 9,
      qs: 'Minimize the Maximum Difference of Pairs',
      link: 'https://leetcode.com/problems/minimize-the-maximum-difference-of-pairs/',
      ytvid:
        'https://www.youtube.com/watch?v=OsG2i00LXFc&list=PLpIkg8OmuX-LkgtrEF7eyyYWJM3m5tVQY&index=21',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Minimize%20the%20Maximum%20Difference%20of%20Pairs.cpp',
      'Company Tags': 'META',
      'What you will learn from this ??': '',
    },
    {
      id: 10,
      qs: 'Maximum Value at a Given Index in a Bounded Array',
      link: 'https://leetcode.com/problems/maximum-value-at-a-given-index-in-a-bounded-array/',
      ytvid: 'https://www.youtube.com/watch?v=d50vvNSrqfk',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Maximum%20Value%20at%20a%20Given%20Index%20in%20a%20Bounded%20Array.cpp',
      'Company Tags': 'GOOGLE',
      'What you will learn from this ??': '',
    },
    {
      id: 11,
      qs: 'Super Egg Drop',
      link: 'https://leetcode.com/problems/super-egg-drop/solution/',
      ytvid: 'Will soon come',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/DP/Super_Egg_Drop.cpp',
      'Company Tags':
        'Amazon, D-E-Shaw, Goldman Sachs, Google, Hike, MakeMyTrip, MAQ Software, Myntra, nearbuy, Opera, Oracle, Philips, Samsung, ServiceNow, Unisys, VMWare',
      'What you will learn from this ??': '',
    },
    {
      id: 12,
      qs: 'Apply Operations to Maximize Frequency Score',
      link: 'https://leetcode.com/problems/apply-operations-to-maximize-frequency-score/',
      ytvid: 'https://www.youtube.com/watch?v=Iaz-8ZQYUvs',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Binary%20Search/Apply%20Operations%20to%20Maximize%20Frequency%20Score.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 13,
      qs: 'Maximum Number That Sum of the Prices Is Less Than or Equal to K',
      link: 'https://leetcode.com/problems/maximum-number-that-sum-of-the-prices-is-less-than-or-equal-to-k/',
      ytvid: 'https://www.youtube.com/watch?v=D5yhQ-bqw9E',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Bit_Magic/Maximum%20Number%20That%20Sum%20of%20the%20Prices%20Is%20Less%20Than%20or%20Equal%20to%20K.cpp',
      'Company Tags': '',
      'What you will learn from this ??':
        'Binary search on answer as well as magic of bit manipulation',
    },
  ],
  binaryTree: [
    {
      id: 1,
      qs: 'Distribute candies in a binary tree',
      link: 'https://www.geeksforgeeks.org/problems/distribute-candies-in-a-binary-tree/1',
      ytvid: 'https://www.youtube.com/watch?v=FmHxY2104hc',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Tree/Distribute%20candies%20in%20a%20binary%20tree.cpp',
      'Company Tags': 'Microsoft',
      'What you will learn from this ??': 'Power of Story to code',
    },
  ],
  designDS: [
    {
      id: 1,
      qs: 'Insert Delete GetRandom O(1)',
      link: 'https://leetcode.com/problems/insert-delete-getrandom-o1/',
      ytvid:
        'https://www.youtube.com/watch?v=3yTnLrNdJGo&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/Insert%20Delete%20GetRandom%20O(1).cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 2,
      qs: 'LRU Cache',
      link: 'https://leetcode.com/problems/lru-cache/',
      ytvid:
        'https://www.youtube.com/watch?v=81h8O-0U5oo&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=2',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/LRU%20Cache.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 3,
      qs: 'LFU Cache',
      link: 'https://leetcode.com/problems/lfu-cache/description/',
      ytvid:
        'https://www.youtube.com/watch?v=-Vch0tHAsOM&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=3',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/LFU%20Cache.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 4,
      qs: 'Design Browser History',
      link: 'https://leetcode.com/problems/design-browser-history/',
      ytvid:
        'https://www.youtube.com/watch?v=FNcanLK6aZs&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=4',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/Design%20Browser%20History.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 5,
      qs: 'Design Add and Search Words Data Structure',
      link: 'https://leetcode.com/problems/design-add-and-search-words-data-structure/',
      ytvid:
        'https://www.youtube.com/watch?v=wyUO7Oq9uS4&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=5',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/Design%20Add%20and%20Search%20Words%20Data%20Structure.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 6,
      qs: 'Design Parking System',
      link: 'https://leetcode.com/problems/design-parking-system/',
      ytvid:
        'https://www.youtube.com/watch?v=ysZ7Rla4w7Y&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=6',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/Design%20Parking%20System.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 7,
      qs: 'Design HashSet',
      link: 'https://leetcode.com/problems/design-hashset/',
      ytvid:
        'https://www.youtube.com/watch?v=Ts9JHEaApfM&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=7',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/Design%20HashSet.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 8,
      qs: 'Design Underground System',
      link: 'https://leetcode.com/problems/design-underground-system/',
      ytvid:
        'https://www.youtube.com/watch?v=AdRVFQo0l7w&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=9',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/Design%20Underground%20System.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 9,
      qs: 'Snapshot Array',
      link: 'https://leetcode.com/problems/snapshot-array/',
      ytvid:
        'https://www.youtube.com/watch?v=yEYoNCom72Q&list=PLpIkg8OmuX-JW5294URE-iwVhl_tdWEP5&index=9',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Design/Snapshot%20Array.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
  ],
  dp1: [
    {
      id: 1,
      qs: 'Maximum Points After Collecting Coins From All Nodes',
      link: 'https://leetcode.com/problems/maximum-points-after-collecting-coins-from-all-nodes/',
      ytvid: 'https://www.youtube.com/watch?v=kJUhjb0K4_M',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/DP/Maximum%20Points%20After%20Collecting%20Coins%20From%20All%20Nodes.cpp',
      'Company Tags': 'Will soon Update',
      'What you will learn from this ??':
        'How to check if a 3D Memoization can be done by 2D Memoization',
    },
    {
      id: 2,
      qs: 'Maximum Balanced Subsequence Sum',
      link: 'https://leetcode.com/problems/maximum-balanced-subsequence-sum/description/',
      ytvid: 'https://www.youtube.com/watch?v=JrG4tbq6efg',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/DP/LIS%20%26%20Variants/Maximum%20Balanced%20Subsequence%20Sum.cpp',
      'Company Tags': 'Will soon Update',
      'What you will learn from this ??':
        "It's another very good LIS variant. Also this code can be used to solve LIS in O(nlogn)",
    },
    {
      id: 3,
      qs: 'String Compression II',
      link: 'https://leetcode.com/problems/string-compression-ii/',
      ytvid: 'https://www.youtube.com/watch?v=6rgO0obJG0k',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/strings/String%20Compression%20II.cpp',
      'Company Tags': 'Adobe, Microsoft, Uber',
      'What you will learn from this ??':
        '4D Recursion + Memo to 2-D Recursion + Memo',
    },
  ],
  dpOnString: [
    {
      id: 1,
      qs: 'Palindromic Substrings',
      link: 'https://leetcode.com/problems/palindromic-substrings/description/',
      ytvid: 'https://www.youtube.com/watch?v=tGAMyZxlwuA',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/DP/DP%20on%20Strings/Palindromic%20Substrings.cpp',
      'Company Tags': 'Google, Meta',
      'What you will learn from this ??':
        'Blueprint for solving String Palindrome problems.',
    },
    {
      id: 2,
      qs: 'Longest Common Subsequence',
      link: 'https://leetcode.com/problems/longest-common-subsequence/',
      ytvid: 'https://www.youtube.com/watch?v=aJNu_DLyOxY',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/DP/Longest%20Common%20Subsequence.cpp',
      'Company Tags':
        'Microsoft, Amazon, FactSet, Hike, Citrix, MakeMyTrip, Paytm, VMWare',
      'What you will learn from this ??': '',
    },
    {
      id: 3,
      qs: 'Printing Longest Common Subsequence',
      link: 'https://www.geeksforgeeks.org/printing-longest-common-subsequence/',
      ytvid: 'Coming Soon',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/DP/DP%20on%20Strings/Printing%20Longest%20Common%20Subsequence.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
  ],
  recursion: [
    {
      id: 1,
      qs: 'Reverse a Stack',
      link: 'https://www.geeksforgeeks.org/problems/reverse-a-stack/1',
      ytvid:
        'https://www.youtube.com/watch?v=aDQeF8Izv5I&list=PLpIkg8OmuX-IBcXsfITH5ql0Lqci1MYPM&index=7',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Recursion/Reverse%20a%20Stack.cpp',
      'Company Tags': 'Jio, Amazon, Airtle, Swiggy',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
    {
      id: 2,
      qs: 'Tower Of Hanoi',
      link: 'https://www.geeksforgeeks.org/problems/tower-of-hanoi-1587115621/1',
      ytvid:
        'https://www.youtube.com/watch?v=FfUE6gtqOB4&list=PLpIkg8OmuX-IBcXsfITH5ql0Lqci1MYPM&index=8',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Recursion/Tower%20of%20Hanoi.cpp',
      'Company Tags': 'Flipkart, Microsoft',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
    {
      id: 3,
      qs: 'Merge Sort',
      link: 'https://www.geeksforgeeks.org/problems/merge-sort/1',
      ytvid:
        'https://www.youtube.com/watch?v=-cQxPZfYDWs&list=PLpIkg8OmuX-IBcXsfITH5ql0Lqci1MYPM&index=9',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Recursion/Merge%20Sort.cpp',
      'Company Tags':
        'Paytm, Amazon, Microsoft, Snapdeal, Oracle, Goldman Sachs, Wipro, Qualcomm, Boomerang Commerce, Grofers, Target Corporation',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
    {
      id: 4,
      qs: 'Quick Sort',
      link: 'https://www.geeksforgeeks.org/problems/quick-sort/1',
      ytvid:
        'https://www.youtube.com/watch?v=4eEWCQveqk8&list=PLpIkg8OmuX-IBcXsfITH5ql0Lqci1MYPM&index=11',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Recursion/Quick%20Sort.cpp',
      'Company Tags':
        'VMWare, Amazon, Microsoft, Samsung, Hike, Ola Cabs, Goldman Sachs, Adobe, SAP Labs, Qualcomm, HSBC, Grofers, Target Corporation',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
    {
      id: 5,
      qs: 'N Queens',
      link: 'https://leetcode.com/problems/n-queens/description/',
      ytvid: 'https://www.youtube.com/watch?v=FOY49yQcbQ4',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Backtracking/N-Queens.cpp',
      'Company Tags':
        'Accolite, Amazon, Visa, MAQ Software, Amdocs, Twitter, Qualcomm, Google',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
    {
      id: 6,
      qs: 'Generate Parentheses',
      link: 'https://leetcode.com/problems/generate-parentheses/description/',
      ytvid:
        'https://www.youtube.com/watch?v=7xkPbffc6w8&list=PLpIkg8OmuX-IBcXsfITH5ql0Lqci1MYPM&index=14',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Backtracking/Generate%20Parentheses.cpp',
      'Company Tags': 'Samsung',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
    {
      id: 7,
      qs: 'Power Set',
      link: 'https://www.geeksforgeeks.org/problems/power-set4302/1',
      ytvid: 'https://www.youtube.com/watch?v=tp9Y2AqBk6s',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Recursion/Power%20Set.cpp',
      'Company Tags': 'Snapdeal',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
    {
      id: 8,
      qs: 'Permutations II',
      link: 'https://leetcode.com/problems/permutations-ii/description/',
      ytvid: 'https://www.youtube.com/watch?v=Vd2YizhbN74',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Backtracking/Permutations%20II.cpp',
      'Company Tags':
        'Google, Accolite, Amazon, OYO Rooms, Samsung, Snapdeal, Citrix, Walmart',
      'What you will learn from this ??':
        'How to write a story - Trust in Recursion',
    },
  ],
  slidingWindow: [
    {
      id: 1,
      qs: 'Sliding Window Maximum',
      link: 'https://leetcode.com/problems/sliding-window-maximum/',
      ytvid: 'https://www.youtube.com/watch?v=29OnjVQ-fk4',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Sliding%20Window%20Maximum.cpp',
      'Company Tags':
        'Media.Net (Directi, 2023 repeated), Google, Zenefits, Microsoft, Zoho, Flipkart, Amazon, Directi, SAP Labs',
      'What you will learn from this ??': '',
    },
    {
      id: 2,
      qs: 'Minimum Window Substring',
      link: 'https://leetcode.com/problems/minimum-window-substring/',
      ytvid:
        'https://www.youtube.com/watch?v=3Bp3OVD1EGc&list=PLpIkg8OmuX-J2Ivo9YdY7bRDstPPTVGvN&index=5',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Minimum%20Window%20Substring.cpp',
      'Company Tags':
        'Google, Amazon, Microsoft, Codenation, FactSet, Atlassian, MakeMyTrip, Streamoid Technologies, Media.net, Airbnb',
      'What you will learn from this ??': '',
    },
    {
      id: 3,
      qs: 'Contains Duplicate II',
      link: 'https://leetcode.com/problems/contains-duplicate-ii/',
      ytvid: 'https://www.youtube.com/watch?v=AyiGBwFlMb8',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Contains%20Duplicate%20II.cpp',
      'Company Tags': 'Airbnb, Palantir Technologies',
      'What you will learn from this ??': '',
    },
    {
      id: 4,
      qs: 'Contains Duplicate III',
      link: 'https://leetcode.com/problems/contains-duplicate-iii/',
      ytvid: 'Will soon come',
      Git: 'Will soon come',
      'Company Tags': 'Palantir, Palantir Technologies, Airbnb',
      'What you will learn from this ??': '',
    },
    {
      id: 5,
      qs: 'Shortest Subarray with Sum at Least K',
      link: 'https://leetcode.com/problems/shortest-subarray-with-sum-at-least-k/',
      ytvid: 'Will soon come',
      Git: 'Will soon come',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 6,
      qs: 'Constrained Subsequence Sum',
      link: 'https://leetcode.com/problems/constrained-subsequence-sum/description/',
      ytvid: '',
      Git: '',
      'Company Tags': 'Google, HyperVerge',
      'What you will learn from this ??': '',
    },
    {
      id: 7,
      qs: 'Find duplicates within a range `k` in an array',
      link: 'https://www.geeksforgeeks.org/check-given-array-contains-duplicate-elements-within-k-distance/',
      ytvid: 'Will soon come',
      Git: 'Will soon come',
      'Company Tags': 'Amazon',
      'What you will learn from this ??': '',
    },
    {
      id: 8,
      qs: 'Frequency of the Most Frequent Element',
      link: 'https://leetcode.com/problems/frequency-of-the-most-frequent-element/',
      ytvid: 'https://www.youtube.com/watch?v=iOqH_JnXIOQ',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Frequency%20of%20the%20Most%20Frequent%20Element.cpp',
      'Company Tags': 'Meta',
      'What you will learn from this ??': '',
    },
    {
      id: 9,
      qs: 'Count Complete Substrings',
      link: 'https://leetcode.com/problems/count-complete-substrings/description/',
      ytvid: 'https://www.youtube.com/watch?v=ygPMLjxaT2Y',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Count%20Complete%20Substrings.cpp',
      'Company Tags': 'will update soon',
      'What you will learn from this ??': 'Amazing use of sliding window',
    },
    {
      id: 10,
      qs: 'Largest Sum Subarray of Size at least K.',
      link: 'https://www.geeksforgeeks.org/problems/largest-sum-subarray-of-size-at-least-k3121/1',
      ytvid: 'Will soon come',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Largest%20Sum%20Subarray%20of%20Size%20at%20least%20K.cpp',
      'Company Tags': 'Facebook, Paytm, Myntra',
      'What you will learn from this ??': 'Amazing use of sliding window',
    },
    {
      id: 11,
      qs: 'Binary Subarrays With Sum',
      link: 'https://leetcode.com/problems/binary-subarrays-with-sum/',
      ytvid: 'https://www.youtube.com/watch?v=5Quv9nnZs34',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Binary%20Subarrays%20With%20Sum.cpp',
      'Company Tags': '',
      'What you will learn from this ??':
        'Cumulative Sum Pattern, Sliding Window',
    },
    {
      id: 12,
      qs: 'Find Subarray With Bitwise AND Closest to K',
      link: 'https://leetcode.com/problems/find-subarray-with-bitwise-and-closest-to-k/description/',
      ytvid: 'https://www.youtube.com/watch?v=BWBKtU8Euno',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Find%20Subarray%20With%20Bitwise%20AND%20Closest%20to%20K.cpp',
      'Company Tags': '',
      'What you will learn from this ??': '',
    },
    {
      id: 13,
      qs: 'Longest Continuous Subarray With Absolute Diff Less\nThan or Equal to Limit',
      link: 'https://leetcode.com/problems/longest-continuous-subarray-with-absolute-diff-less-than-or-equal-to-limit/',
      ytvid: 'https://www.youtube.com/watch?v=xZDbcGB2PQs',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Sliding%20Window/Longest%20Continuous%20Subarray%20With%20Absolute%20Diff%20Less%20Than%20or%20Equal%20to%20Limit.cpp',
      'Company Tags': 'Uber',
      'What you will learn from this ??':
        'Using Sliding Window along with Heap or multiset',
    },
  ],
  stack: [
    {
      id: 1,
      qs: 'Sum of Subarray Minimums',
      link: 'https://leetcode.com/problems/sum-of-subarray-minimums/',
      ytvid: 'https://www.youtube.com/watch?v=HRQB7-D2bi0',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Stack/Sum%20of%20Subarray%20Minimums.cpp',
      'Company Tags': 'Facebook, Amazon, Google',
      'What you will learn from this ??': 'NSR, NSL',
    },
  ],
  string: [
    {
      id: 1,
      qs: 'Reorder Data in Log Files',
      link: 'https://leetcode.com/problems/reorder-data-in-log-files/',
      ytvid: 'will come soon',
      Git: 'will come soon',
      'Company Tags': 'Amazon Online Assessment',
      'What you will learn from this ??': '',
    },
    {
      id: 2,
      qs: 'Add Strings',
      link: 'https://leetcode.com/problems/add-strings/',
      ytvid: 'will come soon',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/strings/Add%20Strings.cpp',
      'Company Tags': 'Bloomberg Phone Interview, Airbnb, Google',
      'What you will learn from this ??': '',
    },
    {
      id: 3,
      qs: 'Single Row Keyboard / Time to Type a String',
      link: 'https://leetcode.com/problems/single-row-keyboard/',
      ytvid: 'will come soon',
      Git: 'will come soon',
      'Company Tags': 'Google OA round',
      'What you will learn from this ??': '',
    },
    {
      id: 4,
      qs: 'Remove Duplicate Letters',
      link: 'https://leetcode.com/problems/remove-duplicate-letters/',
      ytvid: 'https://www.youtube.com/watch?v=rU5p0MRm5zU',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/strings/Remove%20Duplicate%20Letters.cpp',
      'Company Tags': 'GOOGLE, Oracle Online Assessment',
      'What you will learn from this ??': '',
    },
  ],
  stringAlgo: [
    {
      id: 1,
      qs: 'Knuth-Morris-Pratt KMP String Matching Algorithm',
      link: 'https://www.geeksforgeeks.org/problems/search-pattern0205/1',
      ytvid: 'https://www.youtube.com/watch?v=qases-9gOpk',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/strings/String%20Algorithms/Knuth-Morris-Pratt%20KMP%20String%20Matching%20Algorithm.cpp',
      'Company Tags': 'Google',
      'What you will learn from this ??': 'KMP (Pattern Matching Algorithm)',
    },
    {
      id: 2,
      qs: 'Find Beautiful Indices in the Given Array I & II',
      link: 'https://leetcode.com/problems/find-beautiful-indices-in-the-given-array-ii',
      ytvid: 'https://www.youtube.com/watch?v=7Sm0o5lMmfY',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/strings/Find%20Beautiful%20Indices%20in%20the%20Given%20Array%20II.cpp',
      'Company Tags': '',
      'What you will learn from this ??':
        'Application of Knuth-Morris-Pratt or Z-Function algorithms.',
    },
    {
      id: 3,
      qs: 'Number of Subarrays That Match a Pattern I & II',
      link: 'https://leetcode.com/problems/number-of-subarrays-that-match-a-pattern-ii/description/',
      ytvid: 'https://www.youtube.com/watch?v=OEmdc4a1G4Q',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Arrays/Number%20of%20Subarrays%20That%20Match%20a%20Pattern%20I%20and%20II.cpp',
      'Company Tags': '',
      'What you will learn from this ??':
        'Application of Knuth-Morris-Pratt or Z-Function algorithms.',
    },
  ],
  trie: [
    {
      id: 1,
      qs: 'Maximum XOR of Two Numbers in an Array',
      link: 'https://leetcode.com/problems/maximum-xor-of-two-numbers-in-an-array/description/',
      ytvid: 'https://www.youtube.com/watch?v=JS48Hp2_Z4I&t=2s',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Bit_Magic/Maximum%20XOR%20of%20Two%20Numbers%20in%20an%20Array.cpp',
      'Company Tags': 'MICROSOFT',
      'What you will learn from this ??': 'BIT Trie',
    },
    {
      id: 2,
      qs: 'Palindrome Pairs',
      link: 'https://leetcode.com/problems/palindrome-pairs/',
      ytvid: 'Soon coming...',
      Git: 'https://github.com/MAZHARMIK/Interview_DS_Algo/blob/master/Trie/Palindrome%20Pairs.cpp',
      'Company Tags': 'Airbnb',
      'What you will learn from this ??': 'Trie',
    },
  ],
};

const mikSheet = {
  array: [],
  interval: [],
  binarySearch: [],
  bsOnAns: [],
  binaryTree: [],
  designDS: [],
  dp: [],
  dpOnString: [],
  recursion: [],
  slidingWindow: [],
  stack: [],
  string: [],
  stringAlgo: [],
  trie: [],
};
