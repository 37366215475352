import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  Handle,
  Position,
  applyNodeChanges,
  applyEdgeChanges,
  useReactFlow,
  ReactFlowProvider,
} from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { initialNodes } from '../../assets/js/nodes';
import { initialEdges } from '../../assets/js/edges';
import { ScrollArea } from '../../components/ui/scroll-area';
import { Sheet, SheetTrigger } from '../../components/ui/sheet';
import QsSheet from './QsSheet';

// Layout configuration
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, ranksep: 100, nodesep: 50 }); // Adjust these values as needed

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const layoutedElements = getLayoutedElements(initialNodes, initialEdges);

const DsaRoadmap = () => {
  const [nodes, setNodes] = useState(layoutedElements.nodes);
  const [edges, setEdges] = useState(layoutedElements.edges);
  const { fitView } = useReactFlow();
  // eslint-disable-next-line
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  // eslint-disable-next-line
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const CustomNodeComponent = ({ data }) => {
    return (
      <>
        <Handle type="target" position={Position.Top} />
        <Sheet>
          <SheetTrigger className="cursor-pointer rounded-lg bg-slate-300/40 dark:bg-slate-50 text-black px-4 py-2 font-semibold">
            {data.label}
          </SheetTrigger>
          <QsSheet category={data.link} />
        </Sheet>
        <Handle type="source" position={Position.Bottom} />
      </>
    );
  };

  useEffect(() => {
    fitView({ padding: 100 });
  }, [fitView]);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={{ custom: CustomNodeComponent }}
        elementsSelectable={true}
        // onNodesChange={onNodesChange}
        // onEdgesChange={onEdgesChange}
        minZoom={0.8}
        maxZoom={2}>
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

const App = () => (
  <ReactFlowProvider>
    <ScrollArea className="h-full">
      <DsaRoadmap />
    </ScrollArea>
  </ReactFlowProvider>
);

export default App;
