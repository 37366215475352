import React from 'react';
import AvatarComponent from './AvatarComponent';
import { Button } from '../ui/button';

const ReplyForm = ({ replyText, setReplyText, onReplySubmit, user }) => {
  return (
    <form
      onSubmit={onReplySubmit}
      className="flex gap-3 pl-5 ml-5 pt-4 pb-1 border-l-2">
      <AvatarComponent imageUrl={user?.imageUrl} name={user?.name} />
      <textarea
        value={replyText}
        onChange={(e) => setReplyText(e.target.value)}
        className="w-full p-2 rounded-lg bg-muted/40"
        placeholder="Write a reply..."></textarea>
      <Button type="submit" className="mt-2">
        Reply
      </Button>
    </form>
  );
};

export default ReplyForm;
