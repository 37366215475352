import React from 'react';
import Axios from '../services/axios';
import { useCourse } from '../context/useCourseContext';
import { CircleCheckBig } from 'lucide-react';
import { Button } from './ui/button';

const plans = [
  {
    name: 'Free',
    price: 'Free',
    amount: 0,
    duration: 1,
    description:
      'Get a taste of our premium content with a one-month access to select courses and resources.',
    features: [
      'One AI Course',
      'Semester Course',
      "MIK's DSA Sheet",
      'Dev courses',
    ],
  },
  {
    name: 'Trial Adventurer',
    originalPrice: '₹29',
    price: '₹1',
    amount: 1,
    duration: 1,
    description:
      'Get a taste of our premium content with a one-month access to select courses and resources.',
    features: [
      'All Course features',
      'One AI Course',
      'Placement Blogs',
      'Project Baskets',
    ],
  },
  {
    name: 'Semester Seeker',
    originalPrice: '₹180',
    price: '₹49',
    amount: 49,
    duration: 6,
    description:
      'Ideal for those looking to dive deeper, this plan offers a six-month journey through our extensive course library and project baskets.',
    features: ['...', 'Three AI Course', 'Placement Blogs', 'Project Baskets'],
  },
  {
    name: 'Annual Achiever',
    originalPrice: '₹380',
    price: '₹99',
    amount: 99,
    duration: 12,
    description:
      'Unlock all our premium content for a full year, including unlimited access to AI courses and exclusive project baskets.',
    features: [
      '...',
      'Unlimited AI Courses',
      'Placement Blogs',
      'Project Baskets',
    ],
  },
];

const SubscriptionPlans = () => {
  const { user } = useCourse();

  const handleBuy = async (plan) => {
    try {
      const userId = user.id;
      const { data } = await Axios.post('/payment/checkout', {
        userId,
        price: plan.amount,
        duration: plan.duration,
      });
      const order = data.data;

      const resKey = await Axios.get('/payment/key');
      const key = resKey.data.data;

      const options = {
        key: key,
        amount: order.amout,
        currency: 'INR',
        name: 'Brogrammers-AI',
        description: 'Test Transaction',
        image:
          'https://vinit-jains-bucket.s3.ap-south-1.amazonaws.com/logo512.png',
        order_id: order.id,
        callback_url:
          'https://brogrammers-ai.onrender.com/api/v1/payment/verification', //'http://192.168.1.7:2710/api/v1/payment/verification'
        prefill: {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress,
          contact: '',
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#303236',
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.log(error);
    }
  };

  const calculateDiscount = (originalPrice, discountedPrice) => {
    const original = parseFloat(originalPrice.replace('₹', ''));
    const discounted = parseFloat(discountedPrice.replace('₹', ''));
    const discount = ((original - discounted) / original) * 100;
    return discount.toFixed(0);
  };

  const PlanPrice = ({ plan }) => {
    if (plan.amount === 0) {
      return <h2 className="text-xl text-gray-500 text-center pb-6">FREE</h2>;
    }

    const discountPercentage = calculateDiscount(
      plan.originalPrice,
      plan.price
    );

    return (
      <div className="flex flex-row font-semibold justify-center items-end gap-2 pb-4 pt-2">
        <h2 className="text-xl">{plan.price}</h2>
        <span className="line-through text-gray-400 pb-0.5">
          {plan.originalPrice}
        </span>
        <span className="text-sm text-green-600 pb-0.5">
          {discountPercentage}% OFF
        </span>
      </div>
    );
  };

  return (
    <div className="max-w-full align-baseline py-auto h-full">
      <h1 className="text-center text-xl md:text-4xl font-medium leading-snug tracking-wider">
        Ace Your Semester & Beyond
      </h1>
      <p className="text-center text-sm md:text-lg mt-2 px-6">
        Unlock comprehensive AI-generated content, DSA sheets, project baskets,
        and placement blogs. Get exclusive access to semester courses with
        handwritten notes, PYQs, detailed topic reading materials, and curated
        YouTube videos. Start your journey to top CGPA and dream placements
        today!
      </p>
      <div className="h-1 mx-auto bg-indigo-200 w-24 opacity-75 mt-4 rounded"></div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-1 lg:gap-4 px-2 py-10">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`relative rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105`}>
            {index === 2 && (
              <div className="absolute top-0 left-0 w-full bg-gray-200 text-black font-semibold uppercase py-2 text-center tracking-wide">
                Most Popular
              </div>
            )}
            <div className="bg-white text-black rounded-lg shadow-lg overflow-hidden h-full flex flex-col pt-8">
              <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                  {plan.name}
                </h1>
                <PlanPrice plan={plan} />

                {/* <p className="text-sm text-left pb-6">{plan.description}</p> */}
              </div>
              <div className="flex flex-wrap mt-0 sm:mt-1 px-2 sm:px-2 lg:px-6 flex-grow">
                <ul className="w-full">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center mb-1">
                      <div className="rounded-full p-2 fill-current text-green-700">
                        <CircleCheckBig />
                      </div>
                      <span className="text-gray-700 text-lg ml-2">
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex items-center p-8">
                <Button
                  size="lg"
                  // disabled={true}
                  disabled={index === 0}
                  onClick={() => handleBuy(plan)}
                  className="text-lg font-semibold text-white bg-black hover:bg-gray-700 w-full rounded-lg shadow-xl">
                  Select Plan
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
