import React, { useEffect } from 'react';
import { useLocation, Route, Routes } from 'react-router-dom';
import { useCourse } from '../context/useCourseContext';
import { Navigation, Left, Right } from '../components/course';
import { axios } from '../services';

function Course() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const { user, course, setCourse } = useCourse();

  const updateView = async () => {
    if (
      course?._id == null ||
      course?._id === 'undefined' ||
      course === 'undefined'
    )
      return;
    try {
      await axios.patch(`/user/${user?.id}/${course?._id}`);
    } catch (error) {
      console.log('error updating view', error);
    }
  };

  const getCourse = async () => {
    try {
      const { data } = await axios.get(`/course/${id}`);
      setCourse(data.data);
    } catch (error) {
      console.log('error updating view', error);
    }
  };

  useEffect(() => {
    if (!course) getCourse();
    updateView();
    // eslint-disable-next-line
  }, [course, setCourse]);

  return (
    <div className="h-full">
      <Navigation />

      <div className="flex mt-1 h-full">
        <Left />

        <Routes>
          <Route path="handwritten" element={<Right view="handwritten" />} />
          <Route path="practice" element={<Right view="practice" />} />
          <Route path="summary" element={<Right view="summary" />} />
          <Route path="resources" element={<Right view="resources" />} />
          <Route path="*" element={<Right />} />
        </Routes>
      </div>
    </div>
  );
}

export default Course;
