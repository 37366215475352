import React from 'react';
import { blind75 } from '../../../assets/js/blind';
import { Button } from '../../ui/button';
import { Github, Linkedin, Youtube } from 'lucide-react';

function Heading75() {
  return (
    <div className="flex flex-col md:flex-row gap-8 mt-6 mb-8 p-6 bg-pastel-cream rounded-lg shadow-lg">
      <div className="w-full md:w-3/6 bg-pastel-green p-8 rounded-xl shadow-md">
        <h2 className="text-2xl font-semibold text-white mb-12">
          Topics covered:
        </h2>
        <ul className="grid grid-cols-2 gap-4 ml-2">
          {Object.keys(blind75).map((topic, index) => (
            <li key={index}>
              <a
                href={`#topic-${index + 1}`}
                className="text-amber-500 hover:text-teal-600 transition duration-300 ease-in-out">
                {topic}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Right Section: Description and Image */}
      <div className="w-full md:w-3/6 flex flex-col gap-4">
        <h2 className="text-6xl font-semibold mb-2 text-gray-800">
          75 Blind Sheet
        </h2>

        <p className="text-gray-700 custom-font mb-4">
          Learn DSA with this comprehensive sheet created by Code Story with
          MIK. It's well-organized and structured to help you master Data
          Structures and Algorithms from scratch.
        </p>

        <div className="flex items-center gap-4 bg-pastel-blue p-4 rounded-lg shadow-sm">
          <img
            src={
              'https://media.licdn.com/dms/image/v2/D5603AQHQZyGRmbnS1w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1674491926691?e=1732752000&v=beta&t=6DAZzBZ-XRzFRee5crdkLPP0CA0-zr7bSaLld7hPgNk'
            }
            alt="Code Story with MIK"
            className="w-24 h-24 rounded-full border-2 border-indigo-500"
          />
          <div>
            <h3 className="text-lg font-semibold custom-font text-gray-800">
              Code Story with MIK
            </h3>
            <p className="text-sm text-gray-600">
              DSA mentor and YouTuber with a wonderful community of 65K+
              subscribers.
            </p>
            <div className="flex space-x-1 items-center mt-4">
              <Button variant="outline" size="sm" asChild>
                <a
                  target="blank"
                  href="https://github.com/MAZHARMIK/Interview_DS_Algo">
                  <Github />
                </a>
              </Button>
              <Button variant="outline" size="sm" asChild>
                <a
                  target="blank"
                  href="https://linkedin.com/in/mazhar-imam-khan-95a34ab3">
                  <Linkedin />
                </a>
              </Button>
              <Button variant="outline" size="sm" asChild>
                <a
                  target="blank"
                  href="https://www.youtube.com/@codestorywithMIK">
                  <Youtube />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heading75;
