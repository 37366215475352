import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import axios from '../services/axios';
import { useLocation } from 'react-router-dom';

const CommentsContext = createContext();

export function CommentsProvider({ children }) {
  const [story, setStory] = useState();
  const { user } = useUser();
  const [data, setData] = useState([]);

  const location = useLocation();

  const fetchComments = async (storyId) => {
    try {
      const response = await axios.get(`/comments/${storyId}`);
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching comments:', error);
      return [];
    }
  };

  const getBlog = async (id) => {
    try {
      const response = await axios.get(`/placement/${id}`);
      setStory(response.data.data);
    } catch (error) {
      console.error('Error fetching blog:', error);
    }
  };

  const deleteComment = async (commentId) => {
    try {
      await axios.delete(`/comments/${story._id}/${commentId}`);
      setData((prevData) =>
        prevData.filter((comment) => comment._id !== commentId)
      );
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  useEffect(() => {
    if (location.state && location.state.blog) {
      setStory(location.state.blog);
    } else {
      const params = window.location.pathname.split('/');
      const blogId = params[params.length - 1];
      getBlog(blogId);
    }
    // eslint-disable-next-line
  }, [location.state]);

  const handleCommentSubmit = async (commentText) => {
    try {
      const res = await axios.post(`/comments/blog/${story?._id}`, {
        author: user.id,
        comment: commentText,
      });

      setData([
        {
          _id: res.data.data.comments[res.data.data.comments.length - 1],
          author: {
            _id: user.id,
            name: user.fullName,
            imageUrl: user.imageUrl,
          },
          comment: commentText,
          likes: [],
          createdDate: Date.now(),
          replies: [],
        },
        ...data,
      ]);
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  };

  const handleReplySubmit = async (commentId, commentText) => {
    try {
      const response = await axios.patch(`/comments/reply/${commentId}`, {
        author: user.id,
        comment: commentText,
      });
      setData((prevData) =>
        prevData.map((comment) =>
          comment._id === commentId
            ? {
                ...comment,
                replies: [
                  ...comment.replies,
                  {
                    author: { imageUrl: user.imageUrl, name: user.fullName },
                    comment: commentText,
                    createdAt: Date.now,
                  },
                ],
              }
            : comment
        )
      );
      return response.data.data;
    } catch (error) {
      console.error('Error posting reply:', error);
    }
  };

  const continueUpdate = async () => {
    try {
      await axios.patch(`/user/continue/${user.id}/${story._id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (story) {
      continueUpdate();
      fetchComments(story._id);
    }
    // eslint-disable-next-line
  }, [story]);

  return (
    <CommentsContext.Provider
      value={{
        fetchComments,
        handleCommentSubmit,
        handleReplySubmit,
        deleteComment,
        getBlog,
        data,
        user,
        story,
        setStory,
        updateData: (newData) => setData(newData),
      }}>
      {children}
    </CommentsContext.Provider>
  );
}

export const useCommentContext = () => {
  return useContext(CommentsContext);
};
