import React from 'react';
import GoogleDrive from '../GoogleDrive';
import { Separator } from '../ui/separator';
import { useCourse } from '../../context/useCourseContext';

function Resources() {
  const { handleFileSelect, course, fileLinks, isAdmin } = useCourse();
  console.log(isAdmin);

  return (
    <div className="flex items-center justify-center rounded-lg border border-dashed shadow-sm p-3 sm:px-4">
      <div>
        <section className="mb-6">
          <h3 className="font-bold text-xl mb-2">Syllabus</h3>
          <Separator />
          {course?.syllabus ? (
            <iframe
              title="Syllabus"
              src={`https://drive.google.com/file/d/${course?.syllabus}/preview`}
              width="100%"
              height="480"
              allow="autoplay"
              className="mt-4"></iframe>
          ) : (
            <div className="mt-1">
              {isAdmin && (
                <GoogleDrive onFileSelect={handleFileSelect('syllabus')} />
              )}
              {fileLinks.syllabus && (
                <a
                  href={fileLinks.syllabus}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline">
                  View Syllabus
                </a>
              )}
            </div>
          )}
        </section>

        <section className="mb-6">
          <h3 className="font-bold text-xl mb-2">Worksheets</h3>
          <Separator />
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            {course.worksheets?.length > 0 ? (
              course.worksheets.map((sheet, index) => (
                <div key={index}>
                  <h4 className="font-semibold mb-1">{sheet.title}</h4>
                  <iframe
                    title={sheet.title}
                    src={`https://drive.google.com/file/d/${sheet?.link}/preview`}
                    width="100%"
                    height="240"
                  />
                </div>
              ))
            ) : (
              <p>No worksheets available.</p>
            )}
            {fileLinks.worksheets.length > 0 && (
              <>
                {fileLinks.worksheets.map((worksheet, index) => (
                  <div key={index}>
                    <strong>{worksheet.title}:</strong>
                    <iframe
                      title={worksheet.title}
                      src={`https://drive.google.com/file/d/${worksheet.link}/preview`}
                      width="100%"
                      height="240"
                    />
                  </div>
                ))}
              </>
            )}
            <Separator />
          </div>

          <div className="mt-2">
            {isAdmin && (
              <GoogleDrive
                field={true}
                onFileSelect={handleFileSelect('worksheets')}
              />
            )}
          </div>
        </section>

        <section>
          <h3 className="font-bold text-xl mb-2">Additional Resources</h3>
          <Separator />
          <div className="mt-2">
            {isAdmin && (
              <GoogleDrive onFileSelect={handleFileSelect('resources')} />
            )}
            {fileLinks.resources && (
              <div className="mt-2">
                <a
                  href={fileLinks.resources}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline">
                  View Resources
                </a>
              </div>
            )}
          </div>
        </section>

        <section className="mt-6 p-4 rounded-lg shadow-md">
          <h3 className="font-bold text-xl mb-2 text-center">
            All-in-One Study Zone
          </h3>
          <Separator />
          <p className="mt-4 text-center">
            Gather all your study materials and resources in one place, making
            it super easy to stay organized and focused. No more searching
            through different sources - just jump in and start learning
            efficiently!
          </p>
        </section>
      </div>
    </div>
  );
}

export default Resources;
