import React from 'react';
import { useCourse } from '../../context/useCourseContext';
import { Topic, PDFView, Summary, QnA } from './';
import { ScrollArea } from '../ui/scroll-area';
import Resources from './Resources';

function Right() {
  const { course, selectedTopic, selectedToggle } = useCourse();

  const renderContent = () => {
    if (selectedTopic) {
      return <Topic topic={selectedTopic} />;
    }

    switch (selectedToggle) {
      case 'handwritten':
        return <PDFView pdfLink={course.handwrittenNotes} />;
      case 'practice':
        return <QnA id={course?._id} title={course?.title} />;
      case 'summary':
        return <Summary keyNotes={course.keyNotes} />;
      case 'resources':
        return <Resources course={course} />;
      default:
        return <div>Please select a topic or toggle option.</div>;
    }
  };

  return (
    <div className="flex-1 h-full">
      <ScrollArea className="h-full flex flex-col">
        <div className="flex-1 px-2">{renderContent()}</div>
      </ScrollArea>
    </div>
  );
}

export default Right;
