import React, { useState, useEffect } from 'react';
import { Map, Clipboard, Target } from 'lucide-react';
import { Link } from 'react-router-dom';

function CTA() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateMousePosition = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);
    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  const cards = [
    {
      icon: Target,
      title: '75 Blind DSA Sheet',
      description:
        'Tackle the most frequently asked DSA problems curated in this focused 75-question sheet.',
      link: '/dsa/75sheet',
      color: 'from-emerald-400 to-green-600',
    },
    {
      icon: Map,
      title: 'Complete DSA Roadmap',
      description:
        'Embark on a structured journey through all essential DSA topics with this comprehensive roadmap.',
      link: '/dsa',
      color: 'from-violet-400 to-purple-600',
    },
    {
      icon: Clipboard,
      title: 'Complete DSA Sheet',
      description:
        'Access a comprehensive collection of DSA problems to solidify your understanding and prepare for any challenge.',
      link: '/dsa/sheet',
      color: 'from-rose-400 to-pink-600',
    },
  ];

  return (
    <div className="px-2 sm:px-4 lg:px-8 py-2 md:py-4 relative overflow-hidden rounded-lg">
      <div
        className="absolute inset-0 bg-gradient-to-br from-purple-800 to-blue-600 opacity-10"
        style={{
          filter: 'blur(100px)',
          transform: `translate(${mousePosition.x / 20}px, ${
            mousePosition.y / 20
          }px)`,
        }}
      />
      <div className="text-center relative z-10">
        <h2 className="font-bold text-3xl sm:text-3xl md:text-4xl pb-3 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
          Empower Your Journey: The AI-Driven DSA Learning Path!
        </h2>
        <p className="text-base sm:text-lg font-semibold dark:text-gray-300 max-w-3xl mx-auto mt-4">
          Accelerate your coding expertise with personalized AI guidance. Dive
          into Data Structures, sharpen your algorithmic thinking, and
          confidently prepare for coding interviews.
        </p>
      </div>

      <div
        id="cards"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12 relative z-10">
        {cards.map((card, index) => {
          const IconComponent = card.icon;

          return (
            <div
              key={index}
              className="rounded-xl p-6 dark:bg-gray-900 bg-opacity-50 backdrop-blur-md border border-gray-700 hover:border-gray-500 transition-all duration-300 group flex flex-col">
              <div className="flex items-center mb-4">
                <IconComponent className={`w-6 h-6 sm:w-8 sm:h-8 mr-2`} />
                <h3
                  className={`font-bold text-xl sm:text-2xl text-transparent bg-gradient-to-br ${card.color} bg-clip-text`}>
                  {card.title}
                </h3>
              </div>
              <p className="dark:text-gray-300 mb-6 flex-grow dark:group-hover:text-white group-hover:text-zinc-600 transition-colors duration-300">
                {card.description}
              </p>
              <Link
                to={card.link}
                className={`inline-block w-full py-3 px-6 text-center text-white font-semibold rounded-lg bg-gradient-to-r ${card.color} hover:opacity-90 transition-opacity duration-300`}>
                Try Now for Free
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CTA;
