import React from 'react';
import { CardContent, CardHeader, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';

const Step4 = ({ formData, handleChange, handleArrayChange, addNewField }) => (
  <div>
    <CardHeader>
      <CardTitle className="text-2xl font-semibold mb-4 text-center">
        Preparation Tips & Resources Used
      </CardTitle>
    </CardHeader>

    <CardContent>
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Preparation Tips</h3>
        {formData.preparationTips.map((tip, index) => (
          <div key={index} className="mb-4">
            <Label className="text-md">Tip {index + 1}</Label>
            <Input
              type="text"
              placeholder="Things you would like to share"
              name="tip"
              value={tip.tip}
              onChange={(e) => handleArrayChange('preparationTips', index, e)}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
        ))}
        <Button
          type="button"
          onClick={() => addNewField('preparationTips')}
          className="bg-blue-500 text-white py-2 px-4 rounded mt-2">
          Add Field
        </Button>
      </div>

      <div>
        <h3 className="text-xl font-semibold mb-4">Resources Used</h3>
        {formData.resourcesUsed.map((resource, index) => (
          <div key={index} className="mb-4">
            <Label>Resource Name</Label>
            <Input
              type="text"
              placeholder="SDE sheet used or youtube channels, playlists, websites.. obviously brogrammers"
              name="resourceName"
              value={resource.resourceName}
              onChange={(e) => handleArrayChange('resourcesUsed', index, e)}
              className="w-full border border-gray-300 p-2 rounded mb-2"
            />
            <Label>Resource Link</Label>
            <Input
              type="text"
              placeholder="If available you can share the resource link."
              name="resourceLink"
              value={resource.resourceLink}
              onChange={(e) => handleArrayChange('resourcesUsed', index, e)}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
        ))}
        <Button
          type="button"
          onClick={() => addNewField('resourcesUsed')}
          className="bg-blue-500 text-white py-2 px-4 rounded mt-2">
          Add Resource
        </Button>
      </div>
    </CardContent>
  </div>
);

export default Step4;
