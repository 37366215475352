import { useContext } from 'react';
import { SidebarContext } from './SideBar';
import { Link } from 'react-router-dom';

export default function SidebarItem({ icon, text, to, alert }) {
  const { expanded, pathname } = useContext(SidebarContext);

  const isActive = pathname === to;

  return (
    <Link
      to={to}
      className={`
          relative flex items-center ${
            !expanded && 'justify-center'
          } py-2 px-3 my-1
          font-medium rounded-md cursor-pointer
          transition-colors group 
          ${
            isActive
              ? 'bg-gradient-to-tr from-emerald-50 to-yellow-100 text-yellow-600'
              : 'hover:bg-yellow-50 hover:text-gray-600'
          }
      `}>
      {icon}
      <span
        className={`overflow-hidden transition-all ${
          expanded ? 'w-48 ml-3' : 'w-0'
        }`}>
        {text}
      </span>
      {alert && (
        <div
          className={`absolute right-2 w-2 h-2 rounded bg-yellow-400 ${
            expanded ? '' : 'top-2'
          }`}
        />
      )}

      {!expanded && (
        <h1
          className={`
      absolute left-full rounded-md px-2 py-1 ml-10
      bg-indigo-100 text-indigo-800 text-sm
      invisible opacity-20 -translate-x-3 transition-all
      group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
      `}>
          {text}
        </h1>
      )}
    </Link>
  );
}
