import React, { useEffect, useState } from 'react';

import { axios } from '../../services';
import ProjectCard from './ProjectCard';

function Cards() {
  const [projects, setProjects] = useState();
  const getProjects = async () => {
    const { data } = await axios.get('/basket');
    setProjects(data.data);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-12 justify-center items-center pt-5 mb-4 py-4">
      {projects?.map((card, index) => (
        <ProjectCard key={index} card={card} />
      ))}
    </div>
  );
}

export default Cards;
