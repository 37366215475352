import React, { useState, useEffect, useCallback } from 'react';
import Axios from '../../services/axios';
import { Link } from 'react-router-dom';
import { buttonVariants } from '../ui/button';
import CourseCard from '../CourseCard';
import { ArrowUpLeft } from 'lucide-react'; // Added a book icon

function AdminCourses() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCourses = useCallback(async () => {
    try {
      const res = await Axios.get(`/collection/core`);
      setData(res.data.data.courses);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  return (
    <div className="relative text-center  px-1 py-10">
      {/* Heading and View All Button */}
      <div className="flex justify-between items-center pb-6">
        <h2 className="font-bold text-2xl text-brand flex items-center">
          <ArrowUpLeft className="mr-2" /> Revise Your Core Subjects
        </h2>
        <Link
          to="/courses"
          state={{ data }}
          className={buttonVariants({ variant: 'outline' })}>
          View all
        </Link>
      </div>

      {/* Loading Skeleton */}
      {loading && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {[1, 2, 3, 4].map((_, index) => (
            <div
              key={index}
              className="bg-gray-200 animate-pulse rounded-lg p-6 h-40"></div>
          ))}
        </div>
      )}

      {/* Courses Grid */}
      {!loading && data?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {data.map(
            (course, index) =>
              ((window.innerWidth < 768 && index < 2) || // Small screens (show 2 items)
                (window.innerWidth >= 768 &&
                  window.innerWidth < 1024 &&
                  index < 3) || // Medium screens (show 3 items)
                (window.innerWidth >= 1024 && index < 4)) && ( // Large screens (show 4 items)
                <CourseCard key={index} course={course} />
              )
          )}
        </div>
      ) : (
        !loading && (
          <p className="text-gray-500 mt-6">
            No courses available at the moment.
          </p>
        )
      )}
    </div>
  );
}

export default React.memo(AdminCourses);
