import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useUser } from '@clerk/clerk-react';
import { axios } from '../services';

const CourseContext = createContext();

export const CourseContextProvider = ({ children }) => {
  const { user } = useUser();
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedToggle, setSelectedToggle] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [course, setCourseState] = useState();
  const [dbuser, setDbuser] = useState(() => {
    const savedDbuser = localStorage.getItem('dbuser');
    return savedDbuser ? JSON.parse(savedDbuser) : null;
  });
  const [isActive, setActive] = useState(true);
  const [validation, setValidation] = useState(true);
  const [isBro, setBro] = useState(true);
  const [courses, setCourses] = useState([]);

  const saveDbuserToLocalStorage = (dbuser) => {
    localStorage.setItem('dbuser', JSON.stringify(dbuser));
  };

  const deactivateUser = async (dbuser) => {
    try {
      const res = await axios.patch(`/user/subscription/deactivate`, {
        userId: user.id,
        active: false,
        startDate: dbuser.premium.startDate,
        endDate: dbuser.premium.endDate,
      });
      const fetchedUser = res?.data?.data;
      setDbuser(fetchedUser);
      saveDbuserToLocalStorage(fetchedUser);
    } catch (error) {
      console.log(error);
    }
  };

  const getCourses = async () => {
    try {
      const response = await axios.get(`/course/all/${user?.id}`);
      setCourses(response?.data?.data || []);
    } catch (error) {
      alert(error);
    }
  };

  const updateUserState = (fetchedUser) => {
    setActive(fetchedUser?.trial?.isActive);

    const currentDate = new Date();
    const endDate = new Date(fetchedUser.premium.endDate);

    const yearDiff = endDate.getFullYear() - currentDate.getFullYear();
    const monthDiff = endDate.getMonth() - currentDate.getMonth();
    const dayDiff = endDate.getDate() - currentDate.getDate();

    let totalMonths = yearDiff * 12 + monthDiff;

    if (dayDiff < 0) {
      totalMonths--;
    }

    if (currentDate > endDate) {
      deactivateUser(fetchedUser);
    } else if (currentDate < endDate) {
      if (totalMonths > 6) {
        setValidation(true);
      } else if (totalMonths > 1 && courses.length < 3) {
        setValidation(true);
      } else if (courses.length === 0 && isActive) {
        setValidation(true);
      }
      setBro(true);
    }
  };

  const getUser = async () => {
    try {
      const res = await axios.get(`/user/${user.id}`);
      const fetchedUser = res?.data?.data;
      setDbuser(fetchedUser);
      saveDbuserToLocalStorage(fetchedUser);
      await getCourses();
      updateUserState(fetchedUser);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (dbuser) {
      updateUserState(dbuser);
      getCourses();
    } else {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUser();
    }
  }, [user]);

  const [fileLinks, setFileLinks] = useState({
    syllabus: '',
    worksheets: [],
    resources: '',
    handwrittenNotes: '',
  });

  const handleDeleteTopic = async (topic) => {
    try {
      await axios.delete(
        `/course/topic/${course._id}/${encodeURIComponent(topic)}`
      );
      alert('Topic successfully deleted.');
    } catch (error) {
      alert('Error Deleting: ', error);
    }
  };

  const updateCourse = async (updatedCourse) => {
    try {
      await axios.patch(`/course/obj/${course._id}`, updatedCourse);
    } catch (error) {
      console.log(error);
    }
  };

  const getKeynotes = async (courseId, title) => {
    try {
      const course = await axios.patch(`/course/keynotes/${courseId}/${title}`);
      setCourse(course.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileSelect =
    (field) =>
    async ({ title, link }) => {
      if (field === 'worksheets') {
        const newWorksheet = { title, link };
        setFileLinks((prevLinks) => ({
          ...prevLinks,
          worksheets: [...prevLinks.worksheets, newWorksheet],
        }));
      } else {
        setFileLinks((prevLinks) => ({
          ...prevLinks,
          [field]: link,
        }));
      }

      // Update the backend
      try {
        if (field === 'worksheets') {
          await axios.patch(`/course/${course._id}`, {
            worksheets: [
              ...fileLinks.worksheets,
              ...course.worksheets,
              { title, link },
            ],
          });
        } else {
          await axios.patch(`/course/${course._id}`, { [field]: link });
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    };

  const setCourse = async (course) => {
    setCourseState(course);
  };

  const clearSelectedTopic = useCallback(() => {
    setSelectedTopic(null);
  }, []);

  return (
    <CourseContext.Provider
      value={{
        isAdmin: user?.id === course?.userId,
        user,
        dbuser,
        isActive,
        validation,
        isBro,
        course,
        setCourse,
        courses,
        getCourses,
        updateCourse,
        getKeynotes,
        expanded,
        setExpanded,
        handleDeleteTopic,
        selectedTopic,
        setSelectedTopic,
        selectedToggle,
        setSelectedToggle,
        clearSelectedTopic,
        handleFileSelect,
        fileLinks,
      }}>
      {children}
    </CourseContext.Provider>
  );
};

export const useCourse = () => useContext(CourseContext);
