import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '../../ui/card';
import { Code, ArrowRight, CheckCircle } from 'lucide-react';
import CourseCard from '../../CourseCard';
import Axios from '../../../services/axios';

function Language() {
  const [data, setData] = useState([]);

  const getCourses = useCallback(async () => {
    try {
      const res = await Axios.get(`/collection/programming`);
      setData(res.data.data.courses);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="bg-muted/10">
      <div className="p-8">
        {' '}
        <CardHeader className="flex items-start space-x-3">
          <CardTitle>
            <h2 className="text-2xl sm:text-3xl font-extrabold pb-1">
              Learn a Programming Language
            </h2>
            <p className="text-sm mt-1">
              Unlock the power of coding and build a strong foundation for your
              tech career.
            </p>
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6 space-y-4">
          <p className="text-foreground/80 text-lg">
            Mastering a programming language is essential for solving Data
            Structures and Algorithms (DSA) problems. By understanding how to
            implement control structures, use arithmetic operations, and write
            conditional statements, you'll be well-prepared to tackle complex
            DSA challenges.
          </p>
          <ul className="list-disc list-inside mt-4 text-foreground/80">
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>
                Understand control structures like for loops, while loops
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>
                Learn how to perform arithmetic and logical operations
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>
                Master conditional statements to make decisions in code
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>Get comfortable with data types and variables</span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>
                Write functions to break down problems into smaller tasks
              </span>
            </li>
          </ul>
          <h3 className="font-bold text-xl pt-8">Choose your fav language:</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pt-2">
            {data.map((course, index) => (
              <CourseCard key={index} course={course} />
            ))}
          </div>
        </CardContent>
      </div>
      <CardFooter className="flex flex-col sm:flex-row justify-between items-center p-4 bg-muted border-t border-gray-200">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-muted-foreground">
            Step 2 of your journey to mastering DSA
          </span>
          <progress
            value="5"
            max="10"
            className="h-2 w-20 rounded-lg"></progress>
        </div>
        <button className="mt-4 sm:mt-0 flex items-center text-primary hover:text-primary/80 transition-colors">
          Learn more <ArrowRight className="ml-2 h-4 w-4" />
        </button>
      </CardFooter>
    </Card>
  );
}

export default Language;
