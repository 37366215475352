import React from 'react';
import { ToggleGroup, ToggleGroupItem } from '../ui/toggle-group';

function Toggle() {
  return (
    <ToggleGroup
      className="gap-10 pb-5"
      type="single"
      variant="outline"
      defaultValue="a">
      <ToggleGroupItem
        className="rounded-3xl px-8 border-brand shadow-lg"
        value="a">
        Pojects
      </ToggleGroupItem>
      <ToggleGroupItem
        disabled
        className="rounded-3xl px-8 border-brand shadow-lg"
        value="b">
        Course
      </ToggleGroupItem>
    </ToggleGroup>
  );
}

export default Toggle;
