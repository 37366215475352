import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Textarea } from '../ui/textarea';
import { Button } from '../ui/button';
import { Separator } from '../ui/separator';

const Step3 = ({
  formData,
  addNewField,
  removeField,
  handleChange,
  handleArrayChange,
}) => {
  return (
    <div>
      <CardHeader>
        <CardTitle className="text-2xl font-semibold">
          Interview Process (Rounds)
        </CardTitle>
        <CardDescription>
          Like GD, OA, OA-II, Interview (Stages)
        </CardDescription>
      </CardHeader>

      <CardContent>
        <Label className="ml-2 text-lg">Title for the blog</Label>
        <Textarea
          type="text"
          placeholder="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
          className="mb-6"
        />
        {formData.interviewProcess.map((stage, index) => (
          <Card key={index} className="mb-6 p-4 bg-muted/40">
            <div className="flex justify-between items-center mb-2">
              <Label className="font-semibold text-md">{`Round ${
                index + 1
              }`}</Label>
              <Button
                type="button"
                onClick={() => removeField('interviewProcess', index)}
                className="py-1 px-2 rounded bg-red-500 text-white">
                Remove
              </Button>
            </div>

            <div className="ml-4 mb-4">
              <Label className="font-semibold">Stage</Label>
              <Input
                type="text"
                placeholder="Online assessment (OA) / Interview / Group discussion (GD)"
                name="stage"
                value={stage.stage}
                onChange={(e) =>
                  handleArrayChange('interviewProcess', index, e)
                }
                required
                className="w-full border border-gray-300 p-2 rounded mt-1 mb-2"
              />
              <Label className="font-semibold">Detailed description</Label>
              <Textarea
                type="text"
                name="description"
                placeholder="Description of the round (Questions asked how did you answer etc)"
                value={stage.description}
                onChange={(e) =>
                  handleArrayChange('interviewProcess', index, e)
                }
                required
                className="w-full border border-gray-300 p-2 rounded mt-1"
              />
            </div>

            <div className="ml-4 mb-4 p-1">
              <Label className="font-semibold">Notes</Label>
              <Input
                type="text"
                name="notes"
                placeholder="Important takeaways or notes of that round, like what went well..."
                value={stage.notes}
                onChange={(e) =>
                  handleArrayChange('interviewProcess', index, e)
                }
                className="w-full border border-gray-300 p-2 rounded mt-1 mb-2"
              />
            </div>
          </Card>
        ))}
        <Separator />

        <Button
          type="button"
          onClick={() => addNewField('interviewProcess')}
          className="py-2 px-4 rounded mt-6 w-full">
          Add Round
        </Button>
      </CardContent>
    </div>
  );
};

export default Step3;
