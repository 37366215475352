import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardContent,
} from '../../ui/card';
import { ArrowRight, FileText, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

function Sheets() {
  return (
    <Card className="bg-muted/10">
      <div className="p-8">
        <CardHeader className="flex items-start space-x-3">
          <FileText className="h-10 w-10 text-primary mb-2" />
          <CardTitle>
            <h2 className="text-2xl sm:text-3xl font-extrabold pb-1">
              DSA Sheets by MIK
            </h2>
            <p className="text-sm mt-1">
              Ace your coding interviews with curated DSA sheets and in-depth
              video solutions.
            </p>
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6 space-y-4">
          <p className="text-foreground/80 text-lg">
            Prepare for your coding interviews with expert-crafted DSA sheets.
            Each sheet contains handpicked problems that cover all essential
            topics, giving you the practice needed to ace interviews and
            assessments.
          </p>
          <ul className="list-disc list-inside mt-4 text-foreground/80">
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>
                Solve the top 75 DSA problems most commonly asked in coding
                interviews.
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>
                Comprehensive solutions explained with easy-to-follow video
                tutorials.
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>
                Get comfortable with advanced data structures and algorithms.
              </span>
            </li>
          </ul>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle className="text-lg font-semibold">
                  Blind 75 DSA Sheet
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-foreground/80">
                  Tackle the most common and essential DSA problems with the
                  Blind 75 sheet, a must-have for anyone preparing for coding
                  interviews.
                </p>
              </CardContent>
              <CardFooter className="flex justify-end">
                <button className="flex items-center text-primary hover:text-primary/80 transition-colors">
                  <Link
                    className="flex justify-center items-center mx-auto my-2"
                    to="/dsa/75sheet">
                    Explore <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </button>
              </CardFooter>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="text-lg font-semibold">
                  Complete DSA Sheet
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-foreground/80">
                  Dive deep into DSA with the comprehensive sheet by MIK,
                  covering all topics with detailed video solutions.
                </p>
              </CardContent>
              <CardFooter className="flex justify-end">
                <button className="flex items-center text-primary hover:text-primary/80 transition-colors">
                  <Link
                    className="flex justify-center items-center mx-auto my-2"
                    to="/dsa/sheet">
                    Explore <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </button>
              </CardFooter>
            </Card>
          </div>
        </CardContent>
      </div>

      <CardFooter className="flex justify-between items-center p-4 bg-muted border-t border-gray-200">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-muted-foreground">
            Step 4: Your roadmap to mastering DSA
          </span>
          <progress
            value="4"
            max="10"
            className="h-2 w-20 rounded-lg"></progress>
        </div>
        <button className="flex items-center text-primary hover:text-primary/80 transition-colors">
          Learn more <ArrowRight className="ml-2 h-4 w-4" />
        </button>
      </CardFooter>
    </Card>
  );
}

export default Sheets;
