import React, { useState } from 'react';
import {
  useMultistepForm,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
} from '../components/form';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from '../components/ui/card';
import { Button } from '../components/ui/button';
import Axios from '../services/axios';
import { ScrollArea } from '../components/ui/scroll-area';
import { Separator } from '../components/ui/separator';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { Loader2 } from 'lucide-react';

const initialFormData = {
  studentName: '',
  academicBackground: '',
  cgpa: '',
  bio: '',
  jobRole: '',
  companyName: '',
  title: '',
  package: typeof Number,
  interviewProcess: [{ stage: '', description: '', notes: '' }],
  preparationTips: [{ tip: '' }],
  personalExperience: '',
  resourcesUsed: [{ resourceName: '', resourceLink: '' }],
  instagram: '',
  tweeter: '',
  linkedIn: '',
  site: '',
  markdown: '',
  submissionDate: new Date(),
  createdAt: new Date(),
};

const PlacementForm = () => {
  const navigate = useNavigate();
  const {
    step,
    formData,
    handleChange,
    handleArrayChange,
    addNewField,
    removeField,
    nextStep,
    prevStep,
  } = useMultistepForm(initialFormData);
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const steps = [
    <Step1 key="step1" formData={formData} handleChange={handleChange} />,
    <Step2 key="step2" formData={formData} handleChange={handleChange} />,
    <Step3
      key="step3"
      formData={formData}
      addNewField={addNewField}
      handleChange={handleChange}
      handleArrayChange={handleArrayChange}
      removeField={removeField}
    />,
    <Step4
      key="step4"
      formData={formData}
      handleChange={handleChange}
      handleArrayChange={handleArrayChange}
      addNewField={addNewField}
    />,
    <Step5 key="step5" formData={formData} handleChange={handleChange} />,
  ];

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      if (step < steps.length) return nextStep();
      setLoading(true);
      await Axios.post(`/placement/${user.id}`, formData);
      alert('Form submitted successfully!');
      navigate('/blogs');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="w-full p-2 mx-auto sm:w-3/4 h-full pt-1">
      <ScrollArea className="h-full">
        <Card className=" bg-muted/40 ">
          <form onSubmit={submitForm} className="flex flex-col h-auto">
            <CardHeader>
              <CardTitle className="pb-1">{`Step ${step}`}/5</CardTitle>
            </CardHeader>
            <Separator />
            <CardContent className="flex-grow">{steps[step - 1]}</CardContent>
            <Separator />
            <CardFooter className="flex justify-between mt-4">
              {step > 1 && (
                <Button type="button" variant="outline" onClick={prevStep}>
                  Previous
                </Button>
              )}
              {step < steps.length && <Button type="submit">Next</Button>}
              {step === steps.length &&
                (loading ? (
                  <Button disabled>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Please wait
                  </Button>
                ) : (
                  <Button type="submit">Submit</Button>
                ))}
            </CardFooter>
          </form>
        </Card>
      </ScrollArea>
    </div>
  );
};

export default PlacementForm;
