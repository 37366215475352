import React, { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';

const faqs = [
  {
    question: 'How do I create an AI course?',
    answer:
      'Click on the "Generate" button on the home screen, enter the topic name and duration. Then click "Generate". An AI course will be created with a roadmap and summary.',
  },
  {
    question: 'How can I get placement prep courses?',
    answer:
      'Purchase a subscription for $29, and you will gain access to all placement prep courses directly from the home screen.',
  },
  {
    question: 'What is included in the placement prep courses?',
    answer:
      'The placement prep courses include notes, summaries, video recommendations, documents, and mind maps for OS, CN, DBMS, and DAA.',
  },
  {
    question: 'What should I do if the payment fails?',
    answer:
      'If the payment fails, please contact us at brogrammers.in@gmail.com for assistance.',
  },
  {
    question: 'Can I get a refund if I am not satisfied?',
    answer:
      'Yes, we offer a 30-day money-back guarantee if you are not satisfied with our courses. Please contact support for more details.',
  },
  {
    question: 'Are there any discounts available for students?',
    answer:
      'Yes, we offer special discounts for students. Please provide a valid student ID to avail of the discount.',
  },
  {
    question: 'Can I access the courses on mobile?',
    answer:
      'Yes, our platform is fully responsive, and you can access all courses on mobile devices.',
  },
  {
    question: 'How often is the course content updated?',
    answer:
      'Our course content is regularly updated to ensure it stays relevant and up-to-date with the latest industry trends.',
  },
];

const Faq = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setPosition({ x, y });
  };

  return (
    <section id="faq" className="py-12 rounded-3xl bg-pastel-cream">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-6xl font-bold text-pastel-green pb-2 mb-6">
          Have questions?
        </h2>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1 ml-4">
            <Accordion className="space-y-4" type="single" collapsible>
              {faqs.map((faq, index) => (
                <AccordionItem key={index} value={`faq-${index}`}>
                  <AccordionTrigger className="text-2xl font-bold hover:no-underline">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent>
                    <p className="text-lg font-semibold">{faq.answer}</p>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          <div className="flex-none w-full md:w-1/3 bg-pastel-white h-[250px] p-6 rounded-lg shadow-lg flex flex-col justify-center items-center text-center">
            <h3 className="text-2xl font-bold mb-4">Let's Get Started</h3>
            <p className="text-lg mb-6">
              Start your journey with us by exploring our resources and courses.
            </p>
            <a
              href="#top"
              className="inline-block mx-auto px-6 py-3 bg-pastel-green text-white rounded-full font-semibold text-lg text-center transition-transform duration-300"
              onMouseMove={handleMouseMove}
              style={{
                transform: `translate(${(position.x - 50) / 10}px, ${
                  (position.y - 25) / 10
                }px)`,
              }}>
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
