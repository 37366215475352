import React from 'react';
import { Label } from '../components/ui/label';
import { useSearchParams } from 'react-router-dom';

function Success() {
  const searchQuery = useSearchParams()[0];
  const refNo = searchQuery.get('reference');
  return (
    <div className="flex flex-col flex-1 justify-center items-center mt-5">
      <Label className="text-3xl font-bold mb-2">Order Successfull</Label>
      <p className="text-lg font-semibold">Payment ID: {refNo}</p>
    </div>
  );
}

export default Success;
