import React from 'react';
import { CardContent, CardHeader, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';

const Step1 = ({ formData, handleChange }) => (
  <div>
    <CardHeader>
      <CardTitle>Basic Information</CardTitle>
    </CardHeader>

    <CardContent>
      <Label className="p-2">Student Name</Label>
      <Input
        type="text"
        placeholder="Your name"
        name="studentName"
        value={formData.studentName}
        onChange={handleChange}
        required
        className="p-2"
      />

      <Label className="p-2">Academic Background</Label>
      <Input
        type="text"
        placeholder="Bachelor's in Computer Science"
        name="academicBackground"
        value={formData.academicBackground}
        onChange={handleChange}
        required
        className="p-2"
      />
      <Label className="p-2">CGPA</Label>
      <Input
        type="text"
        placeholder="7.5"
        name="cgpa"
        value={formData.cgpa}
        onChange={handleChange}
        required
      />
      <Label className="p-2 mb-1">Introduction about your self</Label>
      <Textarea
        type="text"
        placeholder="About yourself..."
        name="bio"
        value={formData.bio}
        onChange={handleChange}
        required
      />

      <h2 className="text-lg font-bold mt-5 mb-1">Socials: (Optional) </h2>

      <Input
        type="text"
        name="instagram"
        placeholder="Instagram"
        value={formData.instagram}
        onChange={handleChange}
        className="mb-1"
      />
      <Input
        type="text"
        name="tweeter"
        placeholder="Tweeter"
        value={formData.tweeter}
        onChange={handleChange}
        className="mb-1"
      />
      <Input
        type="text"
        name="linkedIn"
        placeholder="LinkedIn"
        value={formData.linkedin}
        onChange={handleChange}
        className="mb-1"
      />
      <Input
        type="text"
        name="site"
        placeholder="Site link"
        value={formData.site}
        onChange={handleChange}
      />
    </CardContent>
  </div>
);

export default Step1;
