import React from 'react';
import Coin from '../../assets/images/Coin.webp';
import BG from '../../assets/images/bg1.webp';

function Header() {
  return (
    <div
      className="flex flex-col md:flex-row items-center justify-between border-2 my-5 rounded-xl py-4 sm:py-10 shadow-md text-white"
      style={{ backgroundImage: `url(${BG})` }}>
      <div className="text-left md:w-1/2 mb-4 md:mb-0 backdrop-blur-sm p-4 m-2 shadow-glass shadow-slate-50/5 rounded-xl">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4 py-1">
          Project Baskets
        </h1>
        <p className="text-md sm:text-lg">
          Our project baskets are designed to provide students with hands-on
          experience in real-world development scenarios. Each basket includes
          projects that cover a range of technologies and frameworks, ensuring
          you gain relevant and practical skills.
        </p>
      </div>
      <div className="md:w-1/2 flex justify-center">
        <img src={Coin} alt="Coin" className="w-3/4 md:w-4/6 object-contain" />
      </div>
    </div>
  );
}

export default Header;
