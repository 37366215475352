import React, { useState } from 'react';
import { Card, CardContent, CardFooter, CardHeader } from '../ui/card';
import { Heart, Bookmark, BookmarkCheck } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Badge } from '../ui/badge';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';
import { axios, timeAgo } from '../../services';
import { useUser } from '@clerk/clerk-react';
import { usePlacementContext } from '../placement';
import ms from '../../assets/comapnies/5.webp';

function getRandomBgClass() {
  const colors = [
    'bg-gradient-to-r from-cyan-200/10 to-cyan-600',
    'bg-gradient-to-r from-rose-200/10 to-rose-600',
    'bg-gradient-to-r from-teal-200/10 to-teal-600',
    'bg-gradient-to-r from-sky-200/10 to-sky-600',
    'bg-gradient-to-r from-fuchsia-200/10 to-fuchsia-600',
    'bg-gradient-to-r from-violet-200/10 to-violet-600',
    'bg-gradient-to-r from-emerald-200/10 to-emerald-600',
  ];

  return colors[Math.floor(Math.random() * colors.length)];
}

function BlogCard({ blog }) {
  const { user } = useUser();
  const { updateBookmark } = usePlacementContext();

  const [liked, setLiked] = useState(
    blog?.likes?.some((like) => like?.userId === user.id)
  );
  const [likesCount, setLikesCount] = useState(blog?.likes?.length);

  const onBookmark = async () => {
    try {
      await axios.patch(`/user/bookmark/${user?.id}/${blog?._id}`);
      updateBookmark(blog?._id, !blog.bookmarked, blog);
    } catch (error) {
      console.log('error updating bookmark: ', error);
    }
  };

  const onLike = async () => {
    try {
      const newLikedStatus = !liked;
      await axios.patch(`/placement/${user.id}/${blog._id}`);
      setLiked(newLikedStatus);
      setLikesCount((prevCount) => prevCount + (newLikedStatus ? 1 : -1));
    } catch (error) {
      console.log('error updating like: ', error);
    }
  };

  const bottomBgClass = getRandomBgClass();

  return (
    <Card className="relative shadow-lg rounded-lg overflow-hidden w-full sm:max-w-[310px] flex flex-col bg-gray-500 hover:border-primary/30 hover:shadow-xl transition-all duration-300">
      <div className="relative">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{ backgroundImage: `url(${ms})` }}
        />
        <div className={`absolute inset-0 ${bottomBgClass} opacity-70`} />
        <div className="relative bg-gradient-to-r from-gray-600/20 to-black/70 p-4">
          <Link
            to={`/blogs/${blog._id}`}
            state={{ blog }}
            className="flex flex-col h-full">
            <CardHeader className="border-b border-gray-200/30">
              <div className="flex flex-row justify-start gap-5 items-end h-[120px]">
                <Avatar className="w-16 h-16 border-2 border-white/50">
                  <AvatarImage
                    src={
                      blog?.userId?.imageUrl
                        ? blog.userId.imageUrl
                        : 'https://github.com/shadcn.png'
                    }
                  />
                  <AvatarFallback>
                    {blog?.studentName.slice(0, 2).toUpperCase()}
                  </AvatarFallback>
                </Avatar>

                <div>
                  <h2 className="text-xl font-semibold text-white">
                    {blog?.studentName}
                  </h2>
                  <p className="text-sm text-gray-300">
                    {timeAgo(blog?.submissionDate)}
                  </p>
                </div>
              </div>

              <Button
                size="sm"
                variant={blog.bookmarked ? 'solid' : 'outline'}
                className={`absolute top-4 right-4 ${
                  blog.bookmarked
                    ? 'bg-blue-500 border-transparent text-white'
                    : 'border-gray-300 text-gray-300'
                } hover:bg-pink-600 hover:border-pink-600 hover:text-white transition-all duration-200`}
                onClick={onBookmark}>
                {blog.bookmarked ? (
                  <BookmarkCheck className="w-5 h-5" />
                ) : (
                  <Bookmark className="w-5 h-5" />
                )}
              </Button>
            </CardHeader>
          </Link>
        </div>
      </div>
      <CardContent className="space-y-3 py-4 px-5 bg-gray-200 text-black h-1/2">
        <div className="flex justify-between items-center">
          <Badge
            className="px-2 py-1 bg-blue-100 text-blue-800"
            variant="outline">
            {blog?.companyName}
          </Badge>
          <Badge
            className="px-2 py-1 bg-purple-100 text-purple-800"
            variant="secondary">
            {blog?.jobRole}
          </Badge>
        </div>
        <p className="text-2xl font-bold px-2">{blog?.package} LPA</p>
        <div className="text-sm px-2 text-gray-700">
          <p>Chandigarh University</p>
          <p className="mt-1">• {blog?.academicBackground}</p>
          <p>• CGPA: {blog?.cgpa}</p>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between items-center p-3 bg-gray-300">
        <Button
          variant="ghost"
          className="flex items-center space-x-1 hover:bg-gray-400/20"
          onClick={onLike}>
          <Heart
            className={`w-5 h-5 ${
              liked ? 'fill-current text-red-500' : 'text-gray-600'
            }`}
          />
          <span className="text-gray-700">{likesCount}</span>
        </Button>
        <Link
          to={`/blogs/${blog._id}`}
          state={{ blog }}
          className="text-sm font-medium text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-200">
          Read More
        </Link>
      </CardFooter>
    </Card>
  );
}

export default BlogCard;
