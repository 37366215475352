import React, { useState, useEffect } from 'react';
import { axios } from '../../services';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { useCourse } from '../../context/useCourseContext';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import QuizItem from './QuizItem';

function QnA({ id, title }) {
  const { isAdmin } = useCourse();
  const [quizs, setQuizs] = useState([]);
  const [prompt, setPrompt] = useState([]);
  const [topic, setTopic] = useState([]);
  const [loading, setLoading] = useState(false);

  const createQnA = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`/course/quiz/${id}/${title}`, {
        prompt,
        topic,
      });
      setQuizs((prevQuizs) => [...prevQuizs, data.data]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getQnA = async () => {
    try {
      const { data } = await axios.get(`/course/quiz/${id}/${title}`);
      setQuizs(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQnA();
  }, []);

  return (
    <div className="h-full mb-20">
      {quizs.map((quiz) => (
        <QuizItem key={quiz._id} quiz={quiz} />
      ))}
      {isAdmin && (
        <div className="p-4 max-w-md mx-auto rounded shadow-md">
          <div className="mb-4 p-1">
            <Label className="block text-sm font-medium">
              Select Question Type:
            </Label>
            <Select onValueChange={(value) => setPrompt(value)}>
              <SelectTrigger className="w-full mt-1 border border-gray-300 rounded-md shadow-sm p-2">
                <SelectValue placeholder="Select type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Long questions">Long questions</SelectItem>
                <SelectItem value="Short questions">Short questions</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="mb-4 p-1">
            <Label htmlFor="topic-input" className="block text-sm font-medium">
              Enter Topic:
            </Label>
            <Input
              id="topic-input"
              type="text"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </div>
          <Button
            disabled={loading}
            onClick={createQnA}
            className="w-full py-2 bg-brand text-zinc-800 rounded-md shadow-md hover:bg-yellow-600">
            Create Q&A
          </Button>
        </div>
      )}
    </div>
  );
}

export default QnA;
