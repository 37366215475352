// FilterButtons.js
import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

import { ToggleGroup, ToggleGroupItem } from '../ui/toggle-group';
import { usePlacementContext } from '../../context/usePlacementContext';

const FilterButtons = () => {
  const { clearToggle, setToggle, setSort, clearSort } = usePlacementContext();

  const handleToggleChange = async (value) => {
    await clearToggle();
    setToggle(value);
  };

  return (
    <div className="relative flex flex-col md:flex-row  justify-between items-center my-10 space-y-4 md:space-y-0 md:space-x-4">
      <ToggleGroup
        defaultValue="latest"
        type="single"
        onValueChange={handleToggleChange}>
        <ToggleGroupItem variant="outline" value="latest">
          Latest
        </ToggleGroupItem>
        <ToggleGroupItem variant="outline" value="bookmarked">
          Bookmarked
        </ToggleGroupItem>
        <ToggleGroupItem variant="outline" value="continue">
          Continue
        </ToggleGroupItem>
      </ToggleGroup>

      <Select
        onValueChange={(value) => {
          clearSort();
          setSort(value);
        }}>
        <SelectTrigger className="w-[130px]">
          <SelectValue placeholder="Sort by" />
        </SelectTrigger>
        <SelectContent side="bottom">
          {/* <SelectItem value="likes">Most liked</SelectItem> */}
          <SelectItem value="recent">Most recent</SelectItem>
          <SelectItem value="oldest">Oldest</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

export default FilterButtons;
