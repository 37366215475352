import React, { useEffect, useState } from 'react';
import { Label } from '../ui/label';
import axios from '../../services/axios';
import { Reply, Trash, Heart } from 'lucide-react';
import { useCommentContext } from '../../context/useComments';
import timeAgo from '../../services/timeAgo';
import AvatarComponent from './AvatarComponent';
import HeartButton from './HeartButton';
import ReplyComponent from './ReplyComponent';
import ReplyForm from './ReplyForm';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../ui/alert-dialog';
import { Button } from '../ui/button';

function Comment({ comment }) {
  const [replyText, setReplyText] = useState('');
  const [liked, setLiked] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const { handleReplySubmit, deleteComment, user } = useCommentContext();

  const handleLike = async (commentId) => {
    try {
      setLiked(!liked);
      await axios.patch(`/comments/likes/${commentId}/${user.id}`);
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const handleReplyLike = async (commentId, replyId) => {
    try {
      const res = await axios.patch(
        `/comments/reply/likes/${commentId}/${replyId}/${user.id}`
      );
      console.log(res.data.data);
    } catch (error) {
      console.error('Error liking reply:', error);
    }
  };

  const onReplySubmit = async (e) => {
    e.preventDefault();
    await handleReplySubmit(comment._id, replyText);
    setReplyText('');
    setShowReply(false);
  };

  useEffect(() => {
    setLiked(comment?.likes?.includes(user.id));
  }, [comment?.likes, user.id]);

  return (
    <div className="comment flex flex-col gap-3 mb-4 bg-muted/40 p-4 rounded-xl">
      <div className="flex gap-3">
        <AvatarComponent
          imageUrl={comment?.author?.imageUrl}
          name={comment?.author?.name}
        />
        <div className="flex-grow ">
          <Label className="text-sm font-semibold">
            {comment?.author?.name}
          </Label>
          <p className="text-sm">{comment?.comment}</p>
          <p className="text-xs text-gray-500">
            {timeAgo(comment?.createdDate)}
          </p>
          <div className="flex gap-1 mt-2">
            <Button
              variant="ghost"
              className="flex items-center space-x-1 hover:bg-muted"
              onClick={handleLike}>
              <Heart
                className={`w-5 h-5 ${
                  liked ? 'fill-current text-red-500' : ''
                }`}
              />
              <span>{comment.likes.length}</span>
            </Button>
            <button
              onClick={() => setShowReply(!showReply)}
              className={`py-1 px-1.5 rounded-lg bg-transparent hover:bg-muted`}>
              <Reply size={22} />
            </button>
            {user.id === comment?.author?._id && (
              <AlertDialog className="relative ">
                <AlertDialogTrigger
                  className={`py-1 px-1.5 rounded-lg bg-transparent hover:bg-muted`}>
                  <Trash size={22} className="cursor-pointer" />
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure you?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="flex justify-end mt-4">
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => deleteComment(comment._id)}>
                      Delete
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
        </div>
      </div>

      {showReply && (
        <ReplyForm
          replyText={replyText}
          setReplyText={setReplyText}
          onReplySubmit={onReplySubmit}
          user={user}
        />
      )}

      {/* Display Replies */}
      <>
        {comment?.replies
          .slice()
          .reverse()
          .map((reply) => (
            <ReplyComponent
              key={reply._id}
              reply={reply}
              handleReplyLike={handleReplyLike}
              user={user}
            />
          ))}
      </>
    </div>
  );
}

export default Comment;
