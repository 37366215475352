import React from 'react';
import { CommentsProvider } from '../context/useComments';
import Blog from '../components/readBlog/Blog';

function ReadBlog() {
  return (
    <CommentsProvider>
      <div className="h-full py-2 px-1 md:px-4">
        <Blog />
      </div>
    </CommentsProvider>
  );
}

export default ReadBlog;
