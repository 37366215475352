import React from 'react';
import SideBar from './SideBar';
import SidebarItem from './SidebarItem';
import TopBar from './TopBar';
import { CourseContextProvider } from '../context/useCourseContext';
import { ScrollArea } from '../components/ui/scroll-area';
import { ThemeProvider } from './theme-provider';

import {
  BookOpen,
  Briefcase,
  LifeBuoy,
  Home,
  ShoppingBasket,
  Network,
} from 'lucide-react';
import { Toaster } from '../components/ui/sonner';

const Layout = ({ children }) => {
  return (
    <CourseContextProvider>
      <ThemeProvider>
        <ScrollArea className="h-screen">
          <div className="flex h-screen overflow-hidden">
            <SideBar>
              <SidebarItem icon={<Home size={20} />} text="Home" to="/" />
              <SidebarItem
                icon={<BookOpen size={20} />}
                text="Courses"
                to="/courses"
              />
              <SidebarItem
                icon={<Briefcase size={20} />}
                text="Stories"
                to="/blogs"
                alert={true}
              />
              <SidebarItem icon={<Network size={20} />} text="Dsa" to="/dsa" />
              <SidebarItem
                icon={<ShoppingBasket size={20} />}
                text="Dev"
                to="/dev"
              />
              <SidebarItem
                icon={<LifeBuoy size={20} />}
                text="Help"
                to="/help"
              />
            </SideBar>
            <main className="flex-1 flex flex-col overflow-hidden">
              <TopBar />
              <div className="flex-1 overflow-y-auto">{children}</div>
              <Toaster />
            </main>
          </div>
        </ScrollArea>
      </ThemeProvider>
    </CourseContextProvider>
  );
};

export default Layout;
