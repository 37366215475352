import React from 'react';
import { Avatar, AvatarImage, AvatarFallback } from '../ui/avatar';

const AvatarComponent = ({ imageUrl }) => {
  return (
    <Avatar>
      <AvatarImage
        src={imageUrl ? imageUrl : 'https://github.com/shadcn.png'}
      />
      <AvatarFallback>CN</AvatarFallback>
    </Avatar>
  );
};

export default AvatarComponent;
