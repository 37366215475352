import React from 'react';
import { BreadcrumbDemo } from '../components/layout';
import ModeToggle from './ModeToggle';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../components/ui/tooltip';
import { Gem } from 'lucide-react';
import { Button } from '../components/ui/button';
import { useCourse } from '../context/useCourseContext';

const Topbar = () => {
  const { isBro } = useCourse();
  return (
    <div className="sticky top-0 flex justify-between items-center px-1 sm:px-4 border-b border-gray-500 shadow-md h-12 z-50">
      <div className="hidden sm:block sm:w-1/4">
        <div className="overflow-hidden w-full">
          <BreadcrumbDemo />
        </div>
      </div>

      <div className="w-full md:w-1/3 text-center ">
        <h2 className="font-bold text-lg chakra-petch-bold">Brogrammers</h2>
      </div>

      <div className="flex items-center gap-1 p-1 md:w-1/4 justify-end">
        <Button
          variant="ghost"
          size="sm"
          className="px-2.5 hidden sm:block"
          onClick={() => window.open('https://x.com/Brogrammer_ai', '_blank')}>
          <svg
            className="h-3 w-3 fill-current"
            height="23"
            viewBox="0 0 1200 1227"
            width="23"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path>
          </svg>
        </Button>

        {isBro ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant="ghost"
                  size="sm"
                  className="py-2 px-2 hidden sm:block">
                  <Gem className="h-[1.0rem] w-[1.0rem]" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="left">Prime</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <button
            onClick={() =>
              window.open('https://buymeacoffee.com/parakhvini5', '_blank')
            }>
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
              width={135}
            />
          </button>
        )}
        <ModeToggle />
      </div>
    </div>
  );
};

export default Topbar;
