import React from 'react';
import { useCommentContext } from '../../context/useComments';
import { Comment, CommentForm } from './';
import { ScrollArea } from '../ui/scroll-area';

function Comments() {
  const { data, handleCommentSubmit } = useCommentContext();

  return (
    <div className="flex-1 h-full">
      <ScrollArea className="h-full px-4">
        <h3 className="text-lg font-semibold my-4">Comments</h3>
        <CommentForm onCommentSubmit={handleCommentSubmit} />
        <div className="comments-list">
          {data?.map((comment, index) => (
            <Comment key={index} comment={comment} />
          ))}
        </div>
      </ScrollArea>
    </div>
  );
}

export default Comments;
