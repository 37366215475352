import axios from 'axios';
import axiosRetry from 'axios-retry';

const Axios = axios.create({
  baseURL: 'https://brogrammers-ai.onrender.com/api/v1/', // main server
  //baseURL: 'http://localhost:2710/api/v1/', // local server
  timeout: 30000, // 30 seconds timeout, adjust as needed
});

axiosRetry(Axios, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      error?.response?.status >= 500
    );
  },
});

export default Axios;
