import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '../../ui/card';
import { ArrowRight, BookOpen, CheckCircle } from 'lucide-react';

function Basics() {
  return (
    <Card className="bg-muted/10">
      <div className="p-8">
        <CardHeader className="flex items-start space-x-3">
          <CardTitle>
            <h2 className="text-2xl sm:text-3xl font-extrabold pb-1">
              Master the Basics of DSA
            </h2>
            <p className="text-sm mt-1">
              Start your journey with essential concepts and build a strong
              foundation for advanced problem-solving.
            </p>
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6 space-y-4">
          <p className="text-foreground/80 text-lg">
            Understanding the basics of Data Structures and Algorithms (DSA) is
            crucial for efficient problem-solving in computer science. These
            fundamental concepts are key to excelling in technical interviews
            and real-world applications.
          </p>
          <ul className="list-disc list-inside mt-4 text-foreground/80">
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>Introduction to Data Structures and how they work</span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>Understanding Algorithms and their importance</span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>Time and Space Complexity: Measure efficiency</span>
            </li>
            <li className="flex items-center space-x-2">
              <CheckCircle className="h-5 w-5 text-green-500" />
              <span>Common Data Structures: Arrays, Linked Lists, Trees</span>
            </li>
          </ul>
          <h3 className="font-bold text-xl pt-8">
            Begin with the right concepts:
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pt-2">
            {/* Add cards or content here if needed */}
          </div>
        </CardContent>
      </div>
      <CardFooter className="flex flex-col sm:flex-row justify-between items-center p-4 bg-muted border-t border-gray-200">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-muted-foreground">
            Step 1: Laying the foundation
          </span>
          <progress
            value="7.5"
            max="10"
            className="h-2 w-20 rounded-lg"></progress>
        </div>
        <button className="mt-4 sm:mt-0 flex items-center text-primary hover:text-primary/80 transition-colors">
          Learn more <ArrowRight className="ml-2 h-4 w-4" />
        </button>
      </CardFooter>
    </Card>
  );
}

export default Basics;
