import React from 'react';
import { Button } from '../../ui/button';
import { Github, Sparkles } from 'lucide-react';

function Project({ project, index }) {
  return (
    <section
      key={index}
      id={`project-${index}`}
      className="mb-6 rounded-xl bg-muted/40 p-6 md:p-10 shadow-lg transition-shadow hover:shadow-xl">
      <h3 className="text-xl md:text-2xl font-semibold mb-4">{project.name}</h3>
      <div className="mb-2">
        <strong>Complexity:</strong> {project.complexity}
      </div>
      <div className="mb-2">
        <strong>Estimated Time:</strong> {project.et}
      </div>
      <div className="mb-2 flex items-center">
        <strong>More about:</strong>
        <a
          href={project.code}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 text-purple-500 hover:text-purple-700 transition border-2 border-purple-500 hover:border-purple-700 p-1 rounded-xl">
          <Github className="w-5 h-5" />
        </a>
      </div>
      <div className="mb-4">
        <strong>Prerequisites:</strong>
        <ul className="ml-4 mt-2">
          {project.preReq.map((preReq, index) => (
            <li key={index} className="mb-1">
              • {preReq}
            </li>
          ))}
        </ul>
      </div>
      <Button
        disabled={true}
        className="flex items-center px-4 py-2 bg-brand font-semibold text-zinc-800 rounded-lg shadow-md hover:bg-yellow-00 transition">
        <Sparkles className="w-5 h-5 mr-2" />
        Ask AI Buddy
      </Button>
    </section>
  );
}

export default Project;
