import React, { useRef, useEffect } from 'react';
import YouTube from 'react-youtube';

function YoutubeVids({ videoIds }) {
  const playerRefs = useRef({});

  useEffect(() => {
    console.log(videoIds);
    // Clear all previous player references when videoIds change
    playerRefs.current = {};
  }, [videoIds]);

  const handlePlayerReady = (event, videoId) => {
    // Store the player reference for each videoId
    playerRefs.current[videoId] = event.target;
  };

  return (
    <>
      {videoIds?.map((videoId) => (
        <div key={videoId} className="rounded-lg my-2">
          <YouTube
            opts={{
              height: '260px',
              width: '427px',
            }}
            videoId={videoId}
            onReady={(event) => handlePlayerReady(event, videoId)}
          />
        </div>
      ))}
    </>
  );
}

export default YoutubeVids;
