export const initialNodes = [
  {
    id: '1',
    position: { x: 0, y: 0 },
    data: { label: 'Array', link: 'array' },
    type: 'custom',
  },
  {
    id: '2',
    position: { x: 0, y: 0 },
    data: { label: 'String', link: 'string' },
    type: 'custom',
  },
  {
    id: '3',
    position: { x: 0, y: 0 },
    data: { label: 'Two Pointer' },
    type: 'custom',
  },
  {
    id: '4',
    position: { x: 0, y: 0 },
    data: { label: 'Sliding Window', link: 'slidingWindow' },
    type: 'custom',
  },
  {
    id: '5',
    position: { x: 0, y: 0 },
    data: { label: 'Prefix Sum' },
    type: 'custom',
  },
  {
    id: '6',
    position: { x: 0, y: 0 },
    data: { label: 'Hash Map / Set' },
    type: 'custom',
  },
  {
    id: '7',
    position: { x: 0, y: 0 },
    data: { label: 'Stack', link: 'stack' },
    type: 'custom',
  },
  {
    id: '8',
    position: { x: 0, y: 0 },
    data: { label: 'Queue' },
    type: 'custom',
  },
  {
    id: '9',
    position: { x: 0, y: 0 },
    data: { label: 'Linked List' },
    type: 'custom',
  },
  {
    id: '10',
    position: { x: 0, y: 0 },
    data: { label: 'Tree', link: 'binaryTree' },
    type: 'custom',
  },
  {
    id: '11',
    position: { x: 0, y: 0 },
    data: { label: 'Graph' },
    type: 'custom',
  },
  {
    id: '12',
    position: { x: 0, y: 0 },
    data: { label: 'Heap' },
    type: 'custom',
  },
  {
    id: '13',
    position: { x: 0, y: 0 },
    data: { label: 'Binary Search', link: 'binarySearch' },
    type: 'custom',
  },
  {
    id: '14',
    position: { x: 0, y: 0 },
    data: { label: 'Backtracking' },
    type: 'custom',
  },
  {
    id: '15',
    position: { x: 0, y: 0 },
    data: { label: 'DP - 1D' },
    type: 'custom',
  },
  {
    id: '16',
    position: { x: 0, y: 0 },
    data: { label: 'DP - Multidimensional' },
    type: 'custom',
  },
  {
    id: '17',
    position: { x: 0, y: 0 },
    data: { label: 'Binary' },
    type: 'custom',
  },
  {
    id: '18',
    position: { x: 0, y: 0 },
    data: { label: 'Interval', link: 'interval' },
    type: 'custom',
  },
  {
    id: '19',
    position: { x: 0, y: 0 },
    data: { label: 'Trie', link: 'trie' },
    type: 'custom',
  },
  {
    id: '20',
    position: { x: 0, y: 0 },
    data: { label: 'Monotonic Stack' },
    type: 'custom',
  },
  {
    id: '21',
    position: { x: 0, y: 0 },
    data: { label: 'Mathematics' },
    type: 'custom',
  },
  {
    id: '22',
    position: { x: 0, y: 0 },
    data: { label: 'Matrix' },
    type: 'custom',
  },
];

// export const initialNodes = [
//   {
//     id: '1',
//     position: { x: 0, y: 0 },
//     data: { label: 'Array' },
//     type: 'custom',
//   },
//   {
//     id: '2',
//     position: { x: 0, y: 0 },
//     data: { label: 'Binary' },
//     type: 'custom',
//   },
//   {
//     id: '8',
//     position: { x: 0, y: 0 },
//     data: { label: 'Dynamic Programming' },
//     type: 'custom',
//   },
//   {
//     id: '9',
//     position: { x: 0, y: 0 },
//     data: { label: 'Graph' },
//     type: 'custom',
//   },
//   {
//     id: '5',
//     position: { x: 0, y: 0 },
//     data: { label: 'Interval' },
//     type: 'custom',
//   },
//   {
//     id: '6',
//     position: { x: 0, y: 0 },
//     data: { label: 'Linked List' },
//     type: 'custom',
//   },
//   {
//     id: '7',
//     position: { x: 0, y: 0 },
//     data: { label: 'Matrix' },
//     type: 'custom',
//   },
//   {
//     id: '3',
//     position: { x: 0, y: 0 },
//     data: { label: 'String' },
//     type: 'custom',
//   },
//   {
//     id: '4',
//     position: { x: 0, y: 0 },
//     data: { label: 'Tree' },
//     type: 'custom',
//   },
//   {
//     id: '10',
//     position: { x: 0, y: 0 },
//     data: { label: 'Heap' },
//     type: 'custom',
//   },
// ];
