import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { ScrollArea } from '../ui/scroll-area';
import { useCourse } from '../../context/useCourseContext';
import AddTopic from './AddTopic';
import DeletedTopic from './DeletedTopic';

function Left() {
  const { course, setSelectedTopic, expanded } = useCourse();

  return (
    <div
      className={`${
        expanded
          ? 'w-full md:w-4/12 animate-width-expand'
          : 'w-0 animate-width-collapse'
      } h-full border-r-2`}>
      <ScrollArea className="h-full px-2">
        {course?.units?.length > 0 ? (
          course.units.map((unit, unitIndex) => (
            <Accordion key={unitIndex} type="single" collapsible>
              <AccordionItem key={unit.id} value={`item-${unit.id}`}>
                <AccordionTrigger className="text-md">
                  {unit.title}
                </AccordionTrigger>
                {unit.topics.map((topic, topicIndex) => (
                  <AccordionContent
                    key={topicIndex}
                    className="mb-1 p-2 rounded hover:bg-muted flex justify-between items-center group">
                    <span
                      className="cursor-pointer w-full"
                      onClick={() => {
                        setSelectedTopic(topic);
                      }}>
                      {topic}
                    </span>
                    <div className="opacity-0 group-hover:opacity-100">
                      <DeletedTopic title={topic} unitId={unit.id} />
                    </div>
                  </AccordionContent>
                ))}
                <AccordionContent>
                  <AddTopic unitId={unit._id} />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ))
        ) : (
          <p>No recently viewed courses available.</p>
        )}
      </ScrollArea>
    </div>
  );
}

export default Left;
