import React, { useState } from 'react';
import { Button } from '../ui/button';
import { useUser } from '@clerk/clerk-react';
import { Textarea } from '../ui/textarea';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';

function CommentForm({ onCommentSubmit }) {
  const [commentText, setCommentText] = useState('');
  const { user } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!commentText.trim()) return;
    await onCommentSubmit(commentText);
    setCommentText('');
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="relative mb-4 bg-muted rounded-xl p-3 pb-20">
      <div className="flex items-start gap-3 p-2">
        <Avatar>
          <AvatarImage src={user.imageUrl} />
          <AvatarFallback>{user.name}</AvatarFallback>
        </Avatar>
        <Textarea
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          className="w-full p-2 bg-muted"
          placeholder="Share your thoughts..."></Textarea>
      </div>
      <Button type="submit" className="absolute mt-2 rounded-lg right-4">
        Comment
      </Button>
    </form>
  );
}

export default CommentForm;
