import React from 'react';
import { Blind75Sheet, Heading75 } from '../../components/dsa/sheet';
import { ScrollArea } from '../../components/ui/scroll-area';

function MIK75Sheet() {
  return (
    <ScrollArea className="container mx-auto h-full">
      <Heading75 />
      <Blind75Sheet />
    </ScrollArea>
  );
}

export default MIK75Sheet;
