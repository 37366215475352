import React from 'react';
import AvatarComponent from './AvatarComponent';
import { Label } from '../ui/label';
import timeAgo from '../../services/timeAgo';
// import { HeartButton } from '.';

const ReplyComponent = ({ reply, handleReplyLike, user }) => {
  return (
    <div
      key={reply?._id}
      className="reply flex gap-3 pl-5 ml-5 pt-4 border-l-2">
      <AvatarComponent
        imageUrl={reply?.author?.imageUrl}
        name={reply?.author?.name}
      />
      <div className="flex-grow ">
        <Label className="text-sm font-semibold">{reply?.author?.name}</Label>
        <p className="text-sm">{reply?.comment}</p>
        <p className="text-xs text-primary/60">{timeAgo(reply?.createdDate)}</p>
        {/* <HeartButton
          onClick={handleReplyLike}
          isLiked={reply.likes.includes(user.id)}
        /> */}
      </div>
    </div>
  );
};

export default ReplyComponent;
