import React from 'react';
import Lottie from 'lottie-react';
import loading from '../../assets/lottie-files/coloredDot.json';

export const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center p-6">
      <h1 className="text-xl font-semibold mb-4">
        Generating topic material and searching for videos...
      </h1>
      <p className="text-md text-muted-foreground text-center mb-6">
        This may take a moment initially, but subsequent loads will be much
        faster.
      </p>
      <Lottie
        animationData={loading}
        loop={true}
        style={{ height: 50, width: 50, marginTop: -10 }}
      />
    </div>
  );
};
