import React from 'react';
import BlogCard from './BlogCard';
import { usePlacementContext } from '../../context/usePlacementContext';

function Latest() {
  const { data } = usePlacementContext();

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-3 md:gap-2 px-4 sm:px-0">
        {data?.map((blog) => (
          <BlogCard
            key={blog?.blog?._id || blog?._id}
            blog={blog?.blog || blog}
          />
        ))}
      </div>
    </div>
  );
}

export default Latest;
