import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link } from 'lucide-react';

const stripMarkdown = (markdownText) => {
  if (typeof markdownText !== 'string') {
    return '';
  }
  return markdownText
    .replace(/[#*_>\[\]\(\)`~\-]/g, '')
    .replace(/\n{2,}/g, '\n')
    .replace(/!\[.*?\]\(.*?\)/g, '')
    .replace(/\[([^\]]*?)\]\(.*?\)/g, '$1');
};

function MarkDownView({ children }) {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [utterance, setUtterance] = useState(null);

  const handleSpeak = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      window.speechSynthesis.speak(utterance);
      setIsSpeaking(true);
    }
  };

  useEffect(() => {
    const plainText = stripMarkdown(children);
    const newUtterance = new SpeechSynthesisUtterance(plainText);
    newUtterance.onend = () => setIsSpeaking(false);
    setUtterance(newUtterance);

    return () => {
      window.speechSynthesis.cancel();
    };
  }, [children]);

  return (
    <>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ node, ...props }) => <p className="mb-6 sm:mb-4" {...props} />,
          h1: ({ node, ...props }) => (
            <h1
              className="text-3xl font-bold my-6 sm:text-2xl sm:my-4"
              {...props}
            />
          ),
          h2: ({ node, ...props }) => (
            <h2
              className="text-2xl font-bold my-6 sm:text-xl sm:my-4"
              {...props}
            />
          ),
          h3: ({ node, ...props }) => (
            <h3
              className="text-xl font-bold my-6 sm:text-lg sm:my-4"
              {...props}
            />
          ),
          h4: ({ node, ...props }) => (
            <h4
              className="text-lg font-bold my-6 sm:text-base sm:my-4"
              {...props}
            />
          ),
          h5: ({ node, ...props }) => (
            <h5
              className="text-base font-bold my-6 sm:text-sm sm:my-4"
              {...props}
            />
          ),
          h6: ({ node, ...props }) => (
            <h6
              className="text-sm font-bold my-6 sm:text-xs sm:my-4"
              {...props}
            />
          ),
          blockquote: ({ node, ...props }) => (
            <blockquote
              className="border-l-4 border-gray-300 pl-4 italic my-6 sm:pl-2 sm:my-4"
              {...props}
            />
          ),
          code: ({ node, inline, className, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            return inline ? (
              <code
                className="bg-gray-200 rounded px-1 text-red-600 sm:text-red-500"
                style={{
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
                {...props}
              />
            ) : (
              <div className="relative my-6 sm:my-4">
                {match && (
                  <div className="absolute right-2 top-2 text-xs text-gray-400 font-mono">
                    {match[1]}
                  </div>
                )}
                <pre className="bg-gray-900 rounded-lg p-4 overflow-x-auto">
                  <code
                    className={`text-gray-100 ${className}`}
                    style={{
                      fontFamily:
                        'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
                    }}
                    {...props}
                  />
                </pre>
              </div>
            );
          },
          ul: ({ node, ...props }) => (
            <ul className="list-inside mb-6 sm:mb-4 pl-4" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className="list-inside mb-6 sm:mb-4 pl-6" {...props} />
          ),
          li: ({ node, ...props }) => (
            <li className="mb-2 pl-2 sm:pl-1" {...props} />
          ),
          a: ({ node, ...props }) => (
            <a
              target="blank"
              className="relative text-blue-600 underline sm:text-blue-500 hover:opacity-75 overflow-hidden text-ellipsis whitespace-nowrap"
              {...props}>
              <span>
                <Link className="inline" /> Link
              </span>
              <span
                className="absolute left-0 bottom-0 bg-gray-700 text-white text-xs px-2 py-1 rounded opacity-0 transition-opacity duration-300 hover:opacity-100"
                style={{ whiteSpace: 'nowrap' }}>
                {props.href}
              </span>
            </a>
          ),
          table: ({ node, ...props }) => (
            <table
              className="min-w-full bg-white border border-gray-300 sm:text-xs"
              {...props}
            />
          ),
          thead: ({ node, ...props }) => (
            <thead className="bg-gray-200">{props.children}</thead>
          ),
          tbody: ({ node, ...props }) => <tbody>{props.children}</tbody>,
          tr: ({ node, ...props }) => (
            <tr className="border-t border-gray-300">{props.children}</tr>
          ),
          th: ({ node, ...props }) => (
            <th className="px-4 py-2 text-left text-gray-700 sm:px-2 sm:py-1">
              {props.children}
            </th>
          ),
          td: ({ node, ...props }) => (
            <td className="px-4 py-2 text-gray-700 sm:px-2 sm:py-1">
              {props.children}
            </td>
          ),
        }}>
        {children}
      </ReactMarkdown>
    </>
  );
}

export default MarkDownView;
