import React from 'react';
import GoogleDrive from '../GoogleDrive';
import { useCourse } from '../../context/useCourseContext';

function PDFView({ pdfLink }) {
  const { handleFileSelect, fileLinks, isAdmin } = useCourse();

  return (
    <div className="flex flex-col h-full">
      {fileLinks.handwrittenNotes || pdfLink ? (
        <div className="min-h-screen">
          <div className="flex-grow">
            <iframe
              title="notes"
              src={`https://drive.google.com/file/d/${
                fileLinks.handwrittenNotes || pdfLink
              }/preview`}
              className="w-full min-h-screen"
            />
          </div>

          {isAdmin && (
            <div className="flex flex-col items-center justify-center p-4 mb-10">
              <p className="text-sm text-muted-foreground">
                You can upload your handwritten notes here.
              </p>
              <GoogleDrive
                onFileSelect={handleFileSelect('handwrittenNotes')}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-center rounded-lg border border-dashed shadow-sm p-20">
            <div className="flex flex-col items-center gap-1 text-center h-full">
              <h3 className="text-2xl font-bold tracking-tight">
                Handwritten Notes
                <div className="h-1 mx-auto bg-gray-200 w-28 opacity-75 my-4 rounded"></div>
              </h3>
              {isAdmin ? (
                <div className="flex flex-col items-center justify-center">
                  <p className="text-sm text-muted-foreground">
                    You can upload your handwritten notes here.
                  </p>
                  <GoogleDrive
                    onFileSelect={handleFileSelect('handwrittenNotes')}
                  />
                </div>
              ) : (
                <p className="text-sm text-muted-foreground">
                  The course admin will upload the handwritten notes here soon.
                  Please check back later.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PDFView;
