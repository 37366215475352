import { useState } from 'react';

const useMultistepForm = (initialFormData, steps) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleArrayChange = (field, index, e) => {
    const updatedArray = [...formData[field]];
    updatedArray[index][e.target.name] = e.target.value;
    setFormData({
      ...formData,
      [field]: updatedArray,
    });
  };

  const addNewField = (field) => {
    const updatedArray = [...formData[field]];
    updatedArray.push({
      stage: '',
      description: '',
      notes: '',
    });
    setFormData({
      ...formData,
      [field]: updatedArray,
    });
  };

  const removeField = (field, index) => {
    const updatedArray = [...formData[field]];
    updatedArray.splice(index, 1);
    setFormData({
      ...formData,
      [field]: updatedArray,
    });
  };

  const addNestedField = (field, parentIndex, subField) => {
    const updatedArray = [...formData[field]];
    updatedArray[parentIndex][subField].push({ question: '', answer: '' });
    setFormData({
      ...formData,
      [field]: updatedArray,
    });
  };

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleSubmit = (e, submitForm) => {
    e.preventDefault();
    if (step < steps.length) {
      nextStep();
    } else {
      submitForm(formData);
    }
  };

  return {
    step,
    formData,
    handleChange,
    handleArrayChange,
    removeField,
    addNewField,
    addNestedField,
    nextStep,
    prevStep,
    handleSubmit,
  };
};

export default useMultistepForm;
