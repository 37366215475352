import React, { useState, useEffect, useCallback } from 'react';
import Axios from '../../services/axios';
import { Link } from 'react-router-dom';
import { buttonVariants } from '../ui/button';
import CourseCard from '../CourseCard';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { ArrowUpLeft } from 'lucide-react';

function AdminCourses() {
  const [uni, setUni] = useState('cu');
  const [sem, setSem] = useState(localStorage.getItem('sem') || 'one');
  const [data, setData] = useState([]);

  const getCourses = useCallback(async () => {
    try {
      const res = await Axios.get(`/collection/${sem}`);
      setData(res.data.data.courses);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [sem, uni]);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line
  }, [sem]);

  useEffect(() => {
    localStorage.setItem('sem', sem);
  }, [sem]);

  return (
    <div className="relative text-center">
      <div className="flex justify-between pb-4 mt-5 p-1 -mx-1">
        <div className="flex flex-col md:flex-row justify-start gap-3 md:py-2 px-1">
          <Select defaultValue="cu" onValueChange={(value) => setUni(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select University" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>University</SelectLabel>
                <SelectItem value="cu">Chandigarh University</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>

          <Select value={sem} onValueChange={(value) => setSem(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a semester" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Semester</SelectLabel>
                <SelectItem value="one">One</SelectItem>
                <SelectItem value="three">Three</SelectItem>
                <SelectItem value="five">Five</SelectItem>
                <SelectItem value="seven">Seven</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <Link
          to="/courses"
          state={{ data }}
          className={buttonVariants({ variant: 'outline' })}>
          View all
        </Link>
      </div>

      {data?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {data?.map(
            (course, index) =>
              ((window.innerWidth < 768 && index < 2) ||
                (window.innerWidth >= 768 &&
                  window.innerWidth < 1024 &&
                  index < 3) || // for medium screens
                (window.innerWidth >= 1024 && index < 4)) && (
                <CourseCard key={index} course={course} />
              )
          )}
        </div>
      ) : (
        <div className="py-10 border-2 border-dashed rounded-lg">
          <div className="mx-4 sm:mx-auto max-w-3xl">
            <p className="mt-2 text-lg sm:text-2xl font-semibold flex items-center justify-center">
              <ArrowUpLeft /> Select your semester in the box
            </p>
            <span className="block text-center mt-3 text-base sm:text-lg">
              Brogrammers maintained courses for efficient learning:
            </span>
            <div className="flex justify-center mt-4 px-4 sm:px-0">
              <div className="text-left">
                <ol className="list-disc list-inside text-base sm:text-lg">
                  <li>Topic Explanation.</li>
                  <li>Video suggestions.</li>
                  <li>Handwritten Notes.</li>
                  <li>Glossary / Summary.</li>
                  <li>Previous Year Questions.</li>
                  <li>MST and Viva questions.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(AdminCourses);
