import * as React from 'react';
import { useState } from 'react';
import { Card, CardContent } from '../ui/card';
import MarkDownView from '../MarkDownView';
import ReactMarkdown from 'react-markdown';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '../ui/button';

const QuestionsCarousel = ({ questions }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % questions.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + questions.length) % questions.length
    );
  };

  return (
    <div className="relative flex items-start justify-center gap-2 w-full overflow-hidden mt-4">
      <Button
        variant="outline"
        size="sm"
        className="px-2.5"
        onClick={handlePrev}>
        <ChevronLeft />
      </Button>

      {questions.length > 0 && (
        <Card className="w-full">
          <CardContent className="p-6">
            <div className="flex items-center text-md text-green-500 font-semibold text-left mb-2">
              <span className="mr-2">Q {currentIndex + 1}.</span>
              <ReactMarkdown className="ml-1 flex-1">
                {questions[currentIndex].question}
              </ReactMarkdown>
            </div>
            <MarkDownView>{questions[currentIndex].ans}</MarkDownView>
          </CardContent>
        </Card>
      )}
      <Button
        variant="outline"
        size="sm"
        className="px-2.5"
        onClick={handleNext}>
        <ChevronRight />
      </Button>
    </div>
  );
};

export default QuestionsCarousel;
