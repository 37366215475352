// Roadmap.js
import React from 'react';
import {
  DSA,
  Language,
  Basics,
  Sheets,
  HoverCard,
} from '../../components/dsa/roadmap';
import { ScrollArea } from '../../components/ui/scroll-area';

function Roadmap() {
  return (
    <ScrollArea className="px-1 sm:container h-full">
      <div className="flex flex-col gap-4 py-5">
        <HoverCard>
          <DSA />
        </HoverCard>
        <HoverCard>
          <Language />
        </HoverCard>
        <HoverCard>
          <Basics />
        </HoverCard>
        <HoverCard>
          <Sheets />
        </HoverCard>
      </div>
    </ScrollArea>
  );
}

export default Roadmap;
