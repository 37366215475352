export const blind75 = {
  Arrays: [
    {
      qs: 'Two Sum',
      yt: 'https://youtu.be/TCaBnVIllrQ?si=kSBXls68X70QCn4B',
      qs_link: 'https://leetcode.com/problems/two-sum/',
      level: 'easy',
    },
    {
      qs: 'Best Time to Buy and Sell Stock',
      yt: 'https://www.youtube.com/watch?v=iTvEXpOAyHY',
      qs_link: 'https://leetcode.com/problems/best-time-to-buy-and-sell-stock/',
      level: 'easy',
    },
    {
      qs: 'Contains Duplicate',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/contains-duplicate/',
      level: 'easy',
    },
    {
      qs: 'Product of Array Except Self',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/product-of-array-except-self/',
      level: 'medium',
    },
    {
      qs: 'Maximum Subarray',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/maximum-subarray/',
      level: 'medium',
    },
    {
      qs: 'Maximum Product Subarray',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/maximum-product-subarray/',
      level: 'medium',
    },
    {
      qs: 'Find Minimum in Rotated Sorted Array',
      yt: 'https://youtu.be/Jin6vO0MdzY?si=LFJy8D86a9ZMVaXI',
      qs_link:
        'https://leetcode.com/problems/find-minimum-in-rotated-sorted-array/',
      level: 'medium',
    },
    {
      qs: 'Search in Rotated Sorted Array',
      yt: 'https://youtu.be/U1VsdRgVevY?si=vp2o5yj5_0kKCq9a',
      qs_link: 'https://leetcode.com/problems/search-in-rotated-sorted-array/',
      level: 'medium',
    },
    {
      qs: '3 Sum',
      yt: 'https://youtu.be/_cBWWebTVpI?si=XzauYdL0Q69_7R2d',
      qs_link: 'https://leetcode.com/problems/3sum/',
      level: 'medium',
    },
    {
      qs: 'Container With Most Water',
      yt: 'https://youtu.be/KVU4JNNJkVg?si=1QHQn6EzDcpkqn7E',
      qs_link: 'https://leetcode.com/problems/container-with-most-water/',
      level: 'medium',
    },
  ],
  Bitmanipulation: [
    {
      qs: 'Number of 1 Bits',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/number-of-1-bits/',
      level: 'easy',
    },
    {
      qs: 'Counting Bits',
      yt: 'https://youtu.be/uwOz378g3ew?si=m37tlkg7OoPoxP_9',
      qs_link: 'https://leetcode.com/problems/counting-bits/',
      level: 'easy',
    },
    {
      qs: 'Missing Number',
      yt: 'https://www.youtube.com/watch?v=lpyQGkbDvqI',
      qs_link: 'https://leetcode.com/problems/missing-number',
      level: 'easy',
    },
    {
      qs: 'Reverse Bits',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/reverse-bits/',
      level: 'easy',
    },
    {
      qs: 'Sum of Two Integers',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/sum-of-two-integers/',
      level: 'medium',
    },
  ],
  'Linked List': [
    {
      qs: 'Reverse a Linked List',
      yt: 'https://youtu.be/RreHsOfi14w?si=fJ5QSp10JSpK9pBI',
      qs_link: 'https://leetcode.com/problems/reverse-linked-list/',
      level: 'easy',
    },
    {
      qs: 'Detect Cycle in a Linked List',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/linked-list-cycle/',
      level: 'easy',
    },
    {
      qs: 'Merge Two Sorted Lists',
      yt: 'https://youtu.be/RreHsOfi14w?si=szPqscASw51HLVUP',
      qs_link: 'https://leetcode.com/problems/merge-two-sorted-lists/',
      level: 'easy',
    },
    {
      qs: 'Merge K Sorted Lists',
      yt: 'https://youtu.be/Q64u-W3l3mA?si=-FmJOCBEhQd04bq5',
      qs_link: 'https://leetcode.com/problems/merge-k-sorted-lists/',
      level: 'hard',
    },
    {
      qs: 'Remove Nth Node From End Of List',
      yt: 'Will soon come',
      qs_link:
        'https://leetcode.com/problems/remove-nth-node-from-end-of-list/',
      level: 'medium',
    },
    {
      qs: 'Reorder List',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/reorder-list/',
      level: 'medium',
    },
  ],
  'Dynamic Programming': [
    {
      qs: 'Climbing Stairs',
      yt: 'https://youtu.be/6OjGE04Kx_M?si=dmjT5_kH7mYW5gIZ',
      qs_link: 'https://leetcode.com/problems/climbing-stairs/',
      level: 'easy',
    },
    {
      qs: 'Coin Change',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/coin-change/',
      level: 'medium',
    },
    {
      qs: 'Longest Increasing Subsequence',
      yt: 'https://youtu.be/DG50PJIx2SM?si=Zn-mVXb-HDC3uSPw',
      qs_link: 'https://leetcode.com/problems/longest-increasing-subsequence/',
      level: 'medium',
    },
    {
      qs: 'Longest Common Subsequence',
      yt: 'https://youtu.be/aJNu_DLyOxY?si=zUbDbMAlhIeIoLYo',
      qs_link: 'https://leetcode.com/problems/longest-common-subsequence/',
      level: 'medium',
    },
    {
      qs: 'Word Break Problem',
      yt: 'https://youtu.be/oBUpyPZ08zU?si=ddvusPWi3624G0mq',
      qs_link: 'https://leetcode.com/problems/word-break/',
      level: 'medium',
    },
    {
      qs: 'Combination Sum',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/combination-sum-iv/',
      level: 'medium',
    },
    {
      qs: 'House Robber',
      yt: 'https://youtu.be/SI6Pm8AKqnQ?si=jpAwTs9gmIrhzo-3',
      qs_link: 'https://leetcode.com/problems/house-robber/',
      level: 'medium',
    },
    {
      qs: 'House Robber II',
      yt: 'https://youtu.be/5AxMZBirNKo?si=dVbPTGY8BVrxMDcm',
      qs_link: 'https://leetcode.com/problems/house-robber-ii/',
      level: 'medium',
    },
    {
      qs: 'Decode Ways',
      yt: 'https://youtu.be/HW-y3gvQTVQ?si=AFgcEG6cfXHZYpF8',
      qs_link: 'https://leetcode.com/problems/decode-ways/',
      level: 'medium',
    },
    {
      qs: 'Unique Paths',
      yt: 'https://youtu.be/DaakAKiCkyc?si=SGSZ0UhFLR4oqEld',
      qs_link: 'https://leetcode.com/problems/unique-paths/',
      level: 'medium',
    },
    {
      qs: 'Jump Game',
      yt: 'https://youtu.be/pvg0yrD-E5w?si=VRKGm93yVnkd-a8o',
      qs_link: 'https://leetcode.com/problems/jump-game/',
      level: 'meidum',
    },
  ],
  'DP on String': [
    {
      qs: 'Longest Substring Without Repeating Characters',
      yt: 'Will soon come',
      qs_link:
        'https://leetcode.com/problems/longest-substring-without-repeating-characters/',
      level: 'medium',
    },
    {
      qs: 'Longest Repeating Character Replacement',
      yt: 'Will soon come',
      qs_link:
        'https://leetcode.com/problems/longest-repeating-character-replacement/',
      level: 'medium',
    },
    {
      qs: 'Minimum Window Substring',
      yt: 'https://www.youtube.com/watch?v=3Bp3OVD1EGc&t=125s',
      qs_link: 'https://leetcode.com/problems/minimum-window-substring/',
      level: 'hard',
    },
    {
      qs: 'Valid Anagram',
      yt: 'https://www.youtube.com/watch?v=1tmEKyRAMuY',
      qs_link: 'https://leetcode.com/problems/valid-anagram/',
      level: 'easy',
    },
    {
      qs: 'Group Anagrams',
      yt: 'https://www.youtube.com/watch?v=TNe3gF4r128',
      qs_link: 'https://leetcode.com/problems/group-anagrams/',
      level: 'medium',
    },
    {
      qs: 'Valid Parentheses',
      yt: 'https://www.youtube.com/watch?v=3ssh_mk4LqY',
      qs_link: 'https://leetcode.com/problems/valid-parentheses/',
      level: 'easy',
    },
    {
      qs: 'Valid Palindrome',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/valid-palindrome/',
      level: 'easy',
    },
    {
      qs: 'Longest Palindromic Substring',
      yt: 'https://www.youtube.com/watch?v=n_kL8BkURVA',
      qs_link: 'https://leetcode.com/problems/longest-palindromic-substring/',
      level: 'medium',
    },
    {
      qs: 'Palindromic Substrings',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/palindromic-substrings/',
      level: 'medium',
    },
    {
      qs: 'Encode and Decode Strings (Leetcode Premium)',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/encode-and-decode-strings/',
      level: 'medium',
    },
  ],
  Graph: [
    {
      qs: 'Clone Graph',
      yt: 'https://www.youtube.com/watch?v=z7mPg_xT5xk',
      qs_link: 'https://leetcode.com/problems/clone-graph/',
      level: 'medium',
    },
    {
      qs: 'Course Schedule',
      yt: 'https://www.youtube.com/watch?v=lqjlGGMjSMU',
      qs_link: 'https://leetcode.com/problems/course-schedule/',
      level: 'medium',
    },
    {
      qs: 'Pacific Atlantic Water Flow',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/pacific-atlantic-water-flow/',
      level: 'medium',
    },
    {
      qs: 'Number of Islands',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/number-of-islands/',
      level: 'medium',
    },
    {
      qs: 'Longest Consecutive Sequence',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/longest-consecutive-sequence/',
      level: 'medium',
    },
    {
      qs: 'Alien Dictionary (Leetcode Premium)',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/alien-dictionary/',
      level: 'medium',
    },
    {
      qs: 'Graph Valid Tree (Leetcode Premium)',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/graph-valid-tree/',
      level: 'medium',
    },
    {
      qs: 'Number of Connected Components in an Undirected Graph (Leetcode Premium)',
      yt: 'Will soon come',
      qs_link:
        'https://leetcode.com/problems/number-of-connected-components-in-an-undirected-graph/',
      level: 'medium',
    },
  ],
  Interval: [
    {
      qs: 'Insert Interval',
      yt: 'https://www.youtube.com/watch?v=XXaVIDIbUn4',
      qs_link: 'https://leetcode.com/problems/insert-interval/',
      level: 'medium',
    },
    {
      qs: 'Merge Intervals',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/merge-intervals/',
      level: 'medium',
    },
    {
      qs: 'Non-overlapping Intervals',
      yt: 'https://www.youtube.com/watch?v=0TYKyTwGOAs',
      qs_link: 'https://leetcode.com/problems/non-overlapping-intervals/',
      level: 'medium',
    },
    {
      qs: 'Meeting Rooms (Leetcode Premium)',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/meeting-rooms/',
      level: 'medium',
    },
    {
      qs: 'Meeting Rooms II (Leetcode Premium)',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/meeting-rooms-ii/',
      level: 'medium',
    },
  ],
  Matrix: [
    {
      qs: 'Set Matrix Zeroes',
      yt: 'https://leetcode.com',
      qs_link: 'https://leetcode.com/problems/set-matrix-zeroes/',
      level: 'medium',
    },
    {
      qs: 'Spiral Matrix',
      yt: 'https://www.youtube.com/watch?v=73j2ipxR958',
      qs_link: 'https://leetcode.com/problems/spiral-matrix/',
      level: 'medium',
    },
    {
      qs: 'Rotate Image',
      yt: 'https://www.youtube.com/watch?v=I48Z8QlQH8c',
      qs_link: 'https://leetcode.com/problems/rotate-image/',
      level: 'medium',
    },
    {
      qs: 'Word Search',
      yt: 'https://www.youtube.com/watch?v=whyax_vB8xY',
      qs_link: 'https://leetcode.com/problems/word-search/',
      level: 'medium',
    },
  ],
  'Binary Tree': [
    {
      qs: 'Maximum Depth of Binary Tree',
      yt: 'https://www.youtube.com/watch?v=uCvur520ejA',
      qs_link: 'https://leetcode.com/problems/maximum-depth-of-binary-tree/',
      level: 'easy',
    },
    {
      qs: 'Same Tree',
      yt: 'https://www.youtube.com/watch?v=YtoibyDlzk0',
      qs_link: 'https://leetcode.com/problems/same-tree/',
      level: 'easy',
    },
    {
      qs: 'Invert/Flip Binary Tree',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/invert-binary-tree/',
      level: 'easy',
    },
    {
      qs: 'Binary Tree Maximum Path Sum',
      yt: 'https://www.youtube.com/watch?v=Op6YFcs8R9M',
      qs_link: 'https://leetcode.com/problems/binary-tree-maximum-path-sum/',
      level: 'hard',
    },
    {
      qs: 'Binary Tree Level Order Traversal',
      yt: 'Will soon come',
      qs_link:
        'https://leetcode.com/problems/binary-tree-level-order-traversal/',
      level: 'medium',
    },
    {
      qs: 'Serialize and Deserialize Binary Tree',
      yt: 'Will soon come',
      qs_link:
        'https://leetcode.com/problems/serialize-and-deserialize-binary-tree/',
      level: 'hard',
    },
    {
      qs: 'Subtree of Another Tree',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/subtree-of-another-tree/',
      level: 'easy',
    },
    {
      qs: 'Construct Binary Tree from Preorder and Inorder Traversal',
      yt: 'https://youtu.be/G5c1wM3Kpuw?si=Nb6oDPbGuwr8d0ZB',
      qs_link:
        'https://leetcode.com/problems/construct-binary-tree-from-preorder-and-inorder-traversal/',
      level: 'medium',
    },
    {
      qs: 'Validate Binary Search Tree',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/validate-binary-search-tree/',
      level: 'medium',
    },
    {
      qs: 'Kth Smallest Element in a BST',
      yt: 'Will soon come',
      qs_link: 'https://leetcode.com/problems/kth-smallest-element-in-a-bst/',
      level: 'medium',
    },
    {
      qs: 'Lowest Common Ancestor of BST',
      yt: 'https://www.youtube.com/watch?v=Oi3_06ultic',
      qs_link:
        'https://leetcode.com/problems/lowest-common-ancestor-of-a-binary-search-tree/',
      level: 'medium',
    },
    {
      qs: 'Implement Trie (Prefix Tree)',
      yt: 'https://www.youtube.com/watch?v=YXefcFMpemo',
      qs_link: 'https://leetcode.com/problems/implement-trie-prefix-tree/',
      level: 'medium',
    },
    {
      qs: 'Add and Search Word',
      yt: 'https://www.youtube.com/watch?v=wyUO7Oq9uS4',
      qs_link:
        'https://leetcode.com/problems/add-and-search-word-data-structure-design/',
      level: 'medium',
    },
    {
      qs: 'Word Search II',
      yt: 'https://www.youtube.com/watch?v=DMP2bqW6URA',
      qs_link: 'https://leetcode.com/problems/word-search-ii/',
      level: 'hard',
    },
  ],
  'Advanced Problems': [
    {
      qs: 'Merge K Sorted Lists',
      yt: 'https://www.youtube.com/watch?v=Q64u-W3l3mA',
      qs_link: 'https://leetcode.com/problems/merge-k-sorted-lists/',
      level: 'hard',
    },
    {
      qs: 'Top K Frequent Elements',
      yt: 'https://www.youtube.com/watch?v=GLw4WbJdYLw',
      qs_link: 'https://leetcode.com/problems/top-k-frequent-elements/',
      level: 'medium',
    },
    {
      qs: 'Find Median from Data Stream',
      yt: 'https://www.youtube.com/watch?v=jnj87BSi9Is',
      qs_link: 'https://leetcode.com/problems/find-median-from-data-stream/',
      level: 'hard',
    },
  ],
};
