import React, { useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { ListPlus } from 'lucide-react';
import { useCourse } from '../../context/useCourseContext';

function AddTopic({ unitId }) {
  const { user, course, updateCourse, setCourse } = useCourse();
  const [visible, setVisible] = useState(false);
  const [topicName, setTopicName] = useState('');

  const handleAddTopic = () => {
    const updatedUnits = course.units.map((unit) => {
      console.log(unit._id, unitId);
      if (unit._id === unitId) {
        return {
          ...unit,
          topics: [...unit.topics, topicName],
        };
      } else {
        return unit;
      }
    });

    const updatedCourse = {
      ...course,
      units: updatedUnits,
    };
    updateCourse(updatedCourse);
    setCourse(updatedCourse);
    setVisible(false);
    setTopicName('');
  };

  return (
    <>
      {course.userId === user.id ? (
        <>
          {visible ? (
            <>
              <Input
                className="mb-1"
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                placeholder="Enter topic name"
              />
              <Button onClick={() => setVisible(false)} size="sm">
                Cancel
              </Button>
              <Button onClick={handleAddTopic} size="sm">
                Submit
              </Button>
            </>
          ) : (
            <Button
              className="w-full"
              onClick={() => setVisible(true)}
              size="sm">
              <ListPlus /> Add a topic
            </Button>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddTopic;
