import React from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from './ui/card';
import {
  NotebookPen,
  Sparkles,
  Boxes,
  ChevronsRight,
  Clock,
} from 'lucide-react';
import { Button } from './ui/button';
import { Link } from 'react-router-dom';
import { timeAgo } from '../services';
import { useCourse } from '../context/useCourseContext';

function CourseCard({ course, viewed }) {
  const lastSeen = timeAgo(viewed);
  const { setCourse, clearSelectedTopic, setSelectedToggle } = useCourse();

  const cardButtons = [
    { icon: NotebookPen, text: 'Handwritten Notes', toggle: 'handwritten' },
    { icon: Boxes, text: 'Questions', toggle: 'practice' },
    { icon: Sparkles, text: 'Glossary', toggle: 'summary' },
  ];

  return (
    <Card className="bg-gradient-to-br from-muted/40 to-muted/20 cursor-pointer h-full hover:border-brand/30 transition-all duration-300 overflow-hidden group">
      <CardHeader className="pb-2">
        <CardTitle className="truncate pb-1 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
          {course?.title}
        </CardTitle>
        {viewed && (
          <CardDescription className="flex items-center text-muted-foreground">
            <Clock size={14} className="mr-1" />
            Viewed {lastSeen}
          </CardDescription>
        )}
      </CardHeader>
      <CardContent className="p-4 space-y-2">
        {cardButtons.map((button, index) => (
          <Button
            key={index}
            className="w-full justify-start hover:bg-zinc-800 dark:hover:bg-amber-50 transition-colors duration-200"
            onClick={() => {
              setCourse(course);
              clearSelectedTopic();
              setSelectedToggle(button.toggle);
            }}
            asChild>
            <Link to={`/courses/${course?.title}?id=${course?._id}`}>
              <button.icon size={18} className="mr-2" />
              {button.text}
            </Link>
          </Button>
        ))}
      </CardContent>
      <CardFooter className="p-4 pt-0">
        <Button
          variant="outline"
          className="w-full justify-between bg-brand text-background hover:bg-primary hover:text-primary-foreground transition-colors duration-200"
          onClick={() => {
            clearSelectedTopic();
            setCourse(course);
          }}
          asChild>
          <Link to={`/courses/${course?.title}?id=${course?._id}`}>
            <span className="font-semibold">Explore Course</span>
            <ChevronsRight
              size={18}
              className="ml-2 transition-transform duration-200 group-hover:translate-x-1"
            />
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}

export default CourseCard;
