import React, { createContext, useContext, useState, useEffect } from 'react';
import { axios } from '../services';
import { useUser } from '@clerk/clerk-react';

const PlacementContext = createContext();

export const PlacementProvider = ({ children }) => {
  const { user } = useUser();
  const [toggle, setToggle] = useState('latest');
  const [sort, setSort] = useState('recent');
  const [fetched, setFetched] = useState([]);
  const [bookmarked, setBookmarked] = useState([]);
  const [continued, setContinue] = useState([]);
  const [data, setData] = useState([]);

  const endpoint = {
    latest: 'latest',
    continue: `continue/${user?.id}`,
    bookmarked: `bookmarked/${user?.id}`,
  };

  const fetchLatestData = async () => {
    const res = await axios.get(`/placement/${endpoint.latest}`);
    return res.data.data;
  };

  const fetchBookmarkedData = async () => {
    const res = await axios.get(`/placement/${endpoint.bookmarked}`);
    return res.data.data.bookMarks;
  };

  const fetchContinueData = async () => {
    const res = await axios.get(`/placement/${endpoint.continue}`);
    return res?.data?.data?.recentBlog;
  };

  const getData = async () => {
    try {
      const [latestData, bookmarkedData] = await Promise.all([
        fetchLatestData(),
        fetchBookmarkedData(),
      ]);
      setBookmarked(bookmarkedData);
      const combinedData = latestData?.map((item) => ({
        ...item,
        bookmarked: bookmarkedData?.some(
          (bookmark) => bookmark?._id === item?._id
        ),
      }));
      setFetched(combinedData);
      setData(combinedData);
    } catch (error) {
      console.log(error);
    }
  };

  const getContinue = async () => {
    try {
      const continueData = await fetchContinueData();
      setContinue(continueData);
      setData(continueData);
    } catch (error) {
      console.log('error fetching continue data', error);
    }
  };

  const updateBookmark = (id, isBookmarked) => {
    setData((prevData) =>
      prevData?.map((item) =>
        item._id === id ? { ...item, bookmarked: isBookmarked } : item
      )
    );

    if (isBookmarked) {
      const newBookmark = data?.find((item) => item?._id === id);
      setBookmarked((prevBookmarked) => [...prevBookmarked, newBookmark]);
    } else {
      setBookmarked((prevBookmarked) =>
        prevBookmarked.filter((bookmark) => bookmark._id !== id)
      );
    }
  };

  const handleLike = async (userId, blogId) => {
    try {
      await axios.patch(`/placement/${userId}/${blogId}`);
    } catch (error) {
      console.log('error updating like: ', error);
    }
  };

  useEffect(() => {
    if (toggle === 'latest') {
      if (!fetched.length) {
        getData();
      } else {
        setData(fetched);
      }
    } else if (toggle === 'bookmarked') {
      setData(bookmarked);
    } else if (toggle === 'continue') {
      if (!continued.length) {
        getContinue();
      } else {
        setData(continued);
      }
    }
    // eslint-disable-next-line
  }, [toggle]);

  const sortData = (data, sort) => {
    const sorted = [...data].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    if (sort === 'oldest') return sorted.reverse();
    return sorted;
  };

  useEffect(() => {
    const sortedData = sortData(data, sort);
    setData(sortedData);
    // eslint-disable-next-line
  }, [sort]);

  return (
    <PlacementContext.Provider
      value={{
        toggle,
        setToggle,
        sort,
        setSort,
        data,
        getData,
        clearToggle: async () => await setToggle(null),
        clearSort: async () => await setSort(null),
        updateBookmark,
        handleLike,
      }}>
      {children}
    </PlacementContext.Provider>
  );
};

export const usePlacementContext = () => {
  return useContext(PlacementContext);
};
