import React from 'react';
import { Book, Briefcase, Code, User, Sailboat, Calendar } from 'lucide-react';

const features = [
  {
    title: 'AI-Powered Revision Courses',
    description:
      'Boost your exam preparation with our AI-generated revision courses. Covering critical topics like OS, CN, and DBMS, these courses provide comprehensive notes and streamlined revision strategies for optimal efficiency.',
    icon: Book,
    iconColor: 'text-pastel-green',
  },
  {
    title: 'Structured DSA Learning Roadmap',
    description:
      'Navigate through our expertly designed DSA roadmap to build your programming skills. Explore curated project baskets and follow a clear path to enhance your expertise and impress potential employers.',
    icon: Code,
    iconColor: 'text-pastel-green',
  },
  {
    title: 'LinkedIn Optimization',
    description:
      'Optimize your LinkedIn profile to stand out in the job market. Get expert tips on crafting an attractive profile that catches the eye of recruiters and showcases your skills effectively.',
    icon: User,
    iconColor: 'text-pastel-green',
  },
  {
    title: 'Placement Blogs and Insider Tips',
    description:
      'Access valuable placement blogs and insider tips from recent graduates and industry experts. Stay informed with the latest trends and strategies to enhance your job search and career development.',
    icon: Briefcase,
    iconColor: 'text-pastel-green',
  },
  {
    title: 'Project Baskets and Buying Options',
    description:
      'Build your portfolio with our curated project baskets or purchase individual projects. Showcase your skills with practical, real-world projects that demonstrate your capabilities to employers.',
    icon: Sailboat,
    iconColor: 'text-pastel-green',
  },
  {
    title: 'Semester Course Packages',
    description:
      'Enroll in our semester-long courses designed to provide in-depth knowledge and skills. Benefit from a structured learning path that covers all essential topics and prepares you for academic success.',
    icon: Calendar,
    iconColor: 'text-pastel-green',
  },
];

const ProductFeatures = () => {
  const pastelColors = [
    'bg-pastel-lavendar',
    'bg-pastel-neon',
    'bg-pastel-blue',
    'bg-pastel-white',
    'bg-pastel-pink',
    'bg-pastel-tan',
  ];

  return (
    <section id="what-we-do" className="my-16">
      <div className="text-center mb-8">
        <h1 className="text-6xl font-bold text-pastel-green pb-4">
          Empowering Your Journey to Success
        </h1>
        <p className="text-2xl font-semibold text-pastel-green md:mx-24">
          At our AI learning platform, we are dedicated to equipping you with
          the essential tools and resources to excel in your academic and
          professional pursuits. Our comprehensive offerings include:
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 py-4">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`flex flex-col p-6 rounded-3xl shadow-lg hover:shadow-xl transition-shadow duration-300 ${
              pastelColors[index % pastelColors.length]
            }`}>
            <feature.icon className={`h-8 w-8 mb-2 ${feature.iconColor}`} />
            <h2 className="text-2xl font-bold text-gray-800">
              {feature.title}
            </h2>
            <p className="text-base my-4 leading-relaxed flex-grow">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductFeatures;
