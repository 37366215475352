import React from 'react';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../ui/table';
import { Github } from 'lucide-react';

function TableComponent({ projectBasket }) {
  return (
    <div className="overflow-x-auto rounded-xl mb-4">
      <Table className="min-w-full">
        <TableCaption>Project List</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="p-2 text-left">Name</TableHead>
            <TableHead className="p-2 text-left">Complexity</TableHead>
            <TableHead className="p-2 text-left">Estimated Time</TableHead>
            <TableHead className="p-2 text-left">More</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {projectBasket?.projects?.map((project, index) => (
            <TableRow key={index}>
              <TableCell>{project.name}</TableCell>
              <TableCell>{project.complexity}</TableCell>
              <TableCell>{project.et}</TableCell>
              <TableCell>
                <a
                  href={project.code}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Github className="w-5 h-5 hover:text-gray-700 transition" />
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default TableComponent;
