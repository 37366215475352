import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
} from '../../ui/card';
import { ArrowRight, CheckCircle } from 'lucide-react';
import YoutubeVids from '../../course/YoutubeVids';

function CardComponent() {
  return (
    <Card className="bg-muted/10 relative overflow-hidden">
      <div className="m-8 flex flex-col lg:flex-row items-center justify-between md:gap-6 lg:gap-1">
        <div className="w-full lg:w-1/2 ">
          <CardHeader>
            <CardTitle className="text-2xl lg:text-3xl font-extrabold">
              What is DSA?
            </CardTitle>

            <CardDescription className="text-sm mt-2">
              Discover the fundamentals of Data Structures and Algorithms
            </CardDescription>
          </CardHeader>
          <CardContent className="mt-4 space-y-3">
            <p className="text-foreground/80">
              DSA stands for Data Structures and Algorithms. It's a foundational
              concept in computer science that focuses on organizing and
              processing data efficiently.
            </p>
            <ul className="list-inside space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-5 w-5 text-green-500" />
                <span>Organize data efficiently</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-5 w-5 text-green-500" />
                <span>Master algorithms to solve complex problems</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-5 w-5 text-green-500" />
                <span>Prepare for technical interviews</span>
              </li>
            </ul>
          </CardContent>
        </div>
        <div className="mx-2 mt-4 lg:mt-0 transition-transform hover:scale-105">
          <YoutubeVids videoIds={['SFEROgwxicA']} />
        </div>
      </div>
      <CardFooter className="flex flex-col lg:flex-row justify-between items-center p-4 bg-muted border-t border-gray-200">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-muted-foreground">
            Step 1 of your DSA journey
          </span>
          <progress
            value="2.5"
            max="10"
            className="h-2 w-20 rounded-lg"></progress>
        </div>
        <button className="mt-2 lg:mt-0 flex items-center text-primary hover:text-primary/80 transition-colors">
          Learn more <ArrowRight className="ml-2 h-4 w-4" />
        </button>
      </CardFooter>
    </Card>
  );
}

export default CardComponent;
