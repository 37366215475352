import React from 'react';
import PricingComponent from '../../components/Pricing';
import Footer from './Footer';

function Pricing() {
  return (
    <div className="md:container md:mt-20">
      <PricingComponent />
      <Footer />
    </div>
  );
}

export default Pricing;
