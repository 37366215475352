import React from 'react';
import { SignOutButton, UserButton, useUser } from '@clerk/clerk-react';
import { LogOut } from 'lucide-react';

function UserInfo({ expanded }) {
  const { user } = useUser();
  return (
    <div
      className={`border-t border-gray-500 flex p-2 ${
        !expanded && 'justify-center'
      }`}>
      <UserButton />
      <div
        className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}
            `}>
        <div className="leading-4">
          <h4 className="font-semibold">{user?.username}</h4>
          <span className="text-md font-semibold">{user?.fullName}</span>
        </div>
        <SignOutButton>
          <LogOut strokeWidth={2} size={20} />
        </SignOutButton>
      </div>
    </div>
  );
}

export default UserInfo;
