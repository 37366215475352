import { PencilLine, Sparkles } from 'lucide-react';
import React, { useState } from 'react';
import Axios from '../../services/axios';
import { Link } from 'react-router-dom';
import { ScrollArea } from '../ui/scroll-area';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '../ui/dialog';
import {
  Accordion,
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
} from '../ui/accordion';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Separator } from '../ui/separator';
import { Progress } from '../ui/progress';
import { useCourse } from '../../context/useCourseContext';

function CreateButton({ children }) {
  const { setCourse, user, validation, isActive } = useCourse();

  const [courseName, setCourseName] = useState('');
  const [timeToComplete, setTimeToComplete] = useState('');
  const [units, setUnits] = useState([{ name: '', topics: [''] }]);
  const [error, setError] = useState(null);
  const [course, setCourseData] = useState(null);
  const [progress, setProgress] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const addUnit = () => {
    setUnits([...units, { name: '', topics: [''] }]);
  };

  const addTopic = (unitIndex) => {
    const newUnits = units.map((unit, index) => {
      if (index === unitIndex) {
        return { ...unit, topics: [...unit.topics, ''] };
      }
      return unit;
    });
    setUnits(newUnits);
  };

  const handleUnitChange = (e, unitIndex) => {
    const newUnits = units.map((unit, index) => {
      if (index === unitIndex) {
        return { ...unit, title: e.target.value };
      }
      return unit;
    });
    setUnits(newUnits);
  };

  const handleTopicChange = (e, unitIndex, topicIndex) => {
    const newUnits = units.map((unit, index) => {
      if (index === unitIndex) {
        const newTopics = unit.topics.map((topic, i) => {
          if (i === topicIndex) {
            return e.target.value;
          }
          return topic;
        });
        return { ...unit, topics: newTopics };
      }
      return unit;
    });
    setUnits(newUnits);
  };

  const simulateProgress = () => {
    let progressValue = 0;
    const totalTime = 20000;
    const intervalTime = 100;

    const steps = totalTime / intervalTime;
    const incrementPerStep = 100 / steps;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + incrementPerStep;
        return Math.min(newProgress, 100);
      });

      progressValue += incrementPerStep;

      if (progressValue >= 100) {
        clearInterval(interval);
      }
    }, intervalTime);

    return interval;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!courseName.trim() || !timeToComplete.trim()) {
      setError('Course Name and Time to Complete are required.');
      return;
    }
    setSubmitting(true);
    setProgress(0);

    const progressInterval = simulateProgress();

    try {
      const unitsData = units.map((unit) => ({
        title: unit.title,
        topics: unit.topics.filter((topic) => topic.trim() !== ''),
      }));
      const res = await Axios.post('/course/new', {
        userId: user.id,
        title: courseName,
        timeToComplete,
        units: unitsData,
      });
      setCourseData(res.data.data);
      setProgress(100);
      setError(null);
    } catch (error) {
      console.error('Error submitting the form:', error);
      setError('Failed to submit the course. Please try again.');
      setProgress(null);
    } finally {
      setSubmitting(false);
      clearInterval(progressInterval);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        {children ? (
          children
        ) : (
          <Button
            className="w-13 border-2 rounded-xl border-zinc-200 m-1"
            variant="outline"
            size="sm">
            <PencilLine />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[700px] ">
        <DialogHeader>
          <DialogTitle className="flex items-center pb-1 gap-2">
            Create Your Custom Roadmap <Sparkles size={20} />
          </DialogTitle>
          <DialogDescription>
            Enter a topic name and time you have to finish. Our AI will craft a
            comprehensive roadmap with curated reading materials, video content,
            summaries, and practice questions just for you. <br /> Topic e.g.
            Subject, Language, Chapter etc.
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit} disabled={submitting}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4 p-1">
              <Label htmlFor="courseName" className="text-right">
                Subject
              </Label>
              <Input
                id="courseName"
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                placeholder="OOPS / Punjabi / Trigonometry"
                className="col-span-3"
                disabled={submitting}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4 p-1">
              <Label htmlFor="timeToComplete" className="text-right">
                Time
              </Label>
              <Input
                id="timeToComplete"
                value={timeToComplete}
                onChange={(e) => setTimeToComplete(e.target.value)}
                placeholder="10 hrs"
                className="col-span-3"
                disabled={submitting}
              />
            </div>
          </div>
          <Separator />
          <Accordion type="single" collapsible>
            <AccordionItem value="a">
              <AccordionTrigger className="text-md">
                Customize your journey: Generate content tailored to your
                specific topics!
              </AccordionTrigger>

              <AccordionContent className="p-3 cursor-pointer rounded-lg hover:bg-muted/40 mb-1">
                <ScrollArea className="h-72 p-3">
                  <DialogDescription>
                    *Leave this field empty to let our AI generate a customized
                    roadmap, complete with curated topics and optimal time
                    allocation.
                  </DialogDescription>

                  {units.map((unit, unitIndex) => (
                    <div key={unitIndex} className="grid gap-4 py-2">
                      <div className="grid grid-cols-4 items-center gap-4 p-1">
                        <Label
                          htmlFor={`unit-${unitIndex}`}
                          className="text-right text-muted-foreground">
                          Unit {unitIndex + 1} (optional)
                        </Label>
                        <Input
                          id={`unit-${unitIndex}`}
                          value={unit.title}
                          onChange={(e) => handleUnitChange(e, unitIndex)}
                          placeholder="Unit name"
                          className="col-span-3 "
                          disabled={submitting}
                        />
                      </div>
                      {unit.topics.map((topic, topicIndex) => (
                        <div
                          key={topicIndex}
                          className="grid grid-cols-4 items-center gap-4 p-1">
                          <Label
                            htmlFor={`topic-${unitIndex}-${topicIndex}`}
                            className="text-right text-muted-foreground">
                            Topic {topicIndex + 1}
                          </Label>
                          <Input
                            id={`topic-${unitIndex}-${topicIndex}`}
                            value={topic}
                            onChange={(e) =>
                              handleTopicChange(e, unitIndex, topicIndex)
                            }
                            placeholder="Topic name"
                            className="col-span-3"
                            disabled={submitting}
                          />
                        </div>
                      ))}
                      <Button
                        size="sm"
                        variant="secondary"
                        type="button"
                        onClick={() => addTopic(unitIndex)}
                        disabled={submitting}>
                        Add Topic
                      </Button>
                    </div>
                  ))}
                  <Button
                    size="sm"
                    variant="outline"
                    type="button"
                    className="w-full mb-2"
                    onClick={addUnit}
                    disabled={submitting}>
                    Add Unit
                  </Button>
                </ScrollArea>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
          {error && <div className="text-red-500">{error}</div>}
          {progress !== null ? (
            <Progress value={progress} />
          ) : (
            <DialogFooter>
              {!validation && !isActive ? (
                <Label className="text-center text-gray-500 font-medium mt-2 p-1">
                  Your trial plan is exhausted{' '}
                  <DialogClose asChild>
                    <Link
                      to="/pricing"
                      className="text-blue-500 font-semibold hover:underline p-3">
                      Buy now!!
                    </Link>
                  </DialogClose>
                </Label>
              ) : (
                <Button type="submit" className="mt-2" disabled={submitting}>
                  Generate
                  <Sparkles className="ml-2 font-light" strokeWidth={1.2} />
                </Button>
              )}
            </DialogFooter>
          )}
        </form>
        {course && (
          <DialogClose asChild>
            <Button asChild>
              <Link
                to={`/courses/${course?.title}?id=${course?._id}`}
                onClick={() => {
                  setCourse(course);
                }}>
                Lets Goo
              </Link>
            </Button>
          </DialogClose>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CreateButton;
