import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/icon.jpg';

function Footer() {
  return (
    <footer className="rounded-xl sm:rounded-full bg-gray-200 my-2 shadow w-full">
      <div className="w-full mx-auto max-w-screen-xl p-4 flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="flex items-center justify-start gap-2 mb-4 md:mb-0">
          <Link to="/">
            <img
              src={logo}
              style={{ height: '38px', borderRadius: 10 }}
              alt="logo"
            />
          </Link>
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{' '}
            <Link to="https://brogrammers.in/" className="hover:underline">
              Brogrammers™
            </Link>
            . All Rights Reserved.
          </span>
        </div>

        <ul className="flex flex-col md:flex-row items-center md:space-x-6 text-sm font-medium text-gray-500 dark:text-gray-400">
          <li className="mb-2 md:mb-0">
            <Link to="/privacy-policies" className="hover:underline">
              Privacy Policy
            </Link>
          </li>
          <li className="mb-2 md:mb-0">
            <Link to="/terms-conditions" className="hover:underline">
              Terms & Conditions
            </Link>
          </li>
          <li className="mb-2 md:mb-0">
            <Link to="/return-refund" className="hover:underline">
              Return & Refund
            </Link>
          </li>
          <li>
            <Link to="/help" className="hover:underline">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
