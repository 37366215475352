import React from 'react';

const BlogHeader = () => {
  return (
    <div className="text-left mb-14 mt-4">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold pb-2 mb-2">
        Unlock Your Dream Placement: <br className="hidden md:block" /> Real
        Student Stories & Insider Tips
      </h1>
      <p className="text-sm sm:text-base md:text-lg text-muted-foreground text-balance">
        Unleash your inner placement pro! Dive deep into real-life student
        journeys that landed them dream jobs. From nailing interviews to acing
        academics, uncover valuable resources and insider tips. Get inspired,
        get informed, and secure your own placement victory – all fueled by the
        success stories of Chandigarh University's brightest stars.
      </p>
    </div>
  );
};

export default BlogHeader;
