import React from 'react';
import { axios } from '../../services';
import { ToggleGroup, ToggleGroupItem } from '../ui/toggle-group';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { useCourse } from '../../context/useCourseContext';
import { Button } from '../ui/button';
import { useUser } from '@clerk/clerk-react';
import {
  EllipsisVertical,
  NotebookPen,
  ScanEye,
  Sparkles,
  Boxes,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';

function Navigation() {
  const {
    course,
    setSelectedToggle,
    clearSelectedTopic,
    isAdmin,
    expanded,
    setExpanded,
  } = useCourse();
  const { user } = useUser();

  const handleDelete = async () => {
    try {
      await axios.delete(`/course/${user.id}/${course?._id}`);
      alert('Course successfully deleted.');
      window.location.href = '/';
    } catch (error) {
      alert('Error Deleting: ', error);
    }
  };

  return (
    <div className="flex items-center border-b-2 relative">
      <div
        className={`flex items-center justify-start ${
          expanded ? 'w-[160px] sm:w-4/12' : 'w-auto'
        } p-1`}>
        <Button
          className="bg-muted/40 left-0"
          onClick={() => setExpanded(!expanded)}
          variant="ghost"
          size="icon">
          {expanded ? <ChevronLeft size={18} /> : <ChevronRight size={18} />}
        </Button>
        {expanded && (
          <h2 className="font-bold text-lg text-center text-brand ml-2">
            Units & Topics
          </h2>
        )}
      </div>

      <ToggleGroup
        variant="outline"
        size="sm"
        type="single"
        className={`flex-grow p-1 ${
          expanded ? 'w-8/12' : 'w-full'
        } grid grid-cols-2 sm:grid-cols-4 gap-2 text-black`}
        onValueChange={(value) => {
          clearSelectedTopic();
          setSelectedToggle(value);
        }}>
        <ToggleGroupItem
          value="handwritten"
          className="flex items-center bg-pastel-neon">
          <NotebookPen size={18} className="mr-1" />
          <span className="hidden sm:inline">Handwritten</span>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="summary"
          className="flex items-center bg-pastel-lavendar">
          <ScanEye size={18} className="mr-1" />
          <span className="hidden sm:inline">Glossary</span>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="resources"
          className="flex items-center bg-pastel-blue">
          <Boxes size={18} className="mr-1" />
          <span className="hidden sm:inline">Resources</span>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="practice"
          className="flex items-center bg-pastel-tan">
          <Sparkles size={18} className="mr-1" />
          <span className="hidden sm:inline">Imp Qs</span>
        </ToggleGroupItem>
      </ToggleGroup>

      {isAdmin && (
        <Popover>
          <PopoverTrigger>
            <EllipsisVertical className="cursor-pointer" />
          </PopoverTrigger>
          <PopoverContent>
            <h2 className="font-semibold text-sm ">
              Are you sure you want to delete this course?
            </h2>
            <p className="text-sm text-muted-foreground mt-1">
              This action cannot be undone. All the data will be erased.
            </p>
            <div className="flex justify-end mt-4">
              <Button
                size="sm"
                variant="destructive"
                onClick={handleDelete}
                className="ml-2">
                Delete
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
}

export default Navigation;
