export const initialEdges = [
  { id: 'e1-2', source: '1', target: '2', targetPosition: 'bottom' }, // Array to String (bottom)
  { id: 'e2-3', source: '2', target: '3', targetPosition: 'bottom' }, // String to Two Pointer (bottom)
  { id: 'e2-4', source: '2', target: '4', targetPosition: 'right' }, // String to Sliding Window (right)
  { id: 'e4-5', source: '4', target: '5', targetPosition: 'bottom' }, // Sliding window to Prefix Sum (bottom)
  { id: 'e5-19', source: '5', target: '19', targetPosition: 'bottom' }, // Prefix Sum to Intervals (bottom)
  { id: 'e1-6', source: '1', target: '6', targetPosition: 'right' }, // Array to Hash Map/Set (right)
  { id: 'e6-7', source: '6', target: '7', targetPosition: 'bottom' }, // Hash Map/Set to Stack (bottom)
  { id: 'e6-8', source: '6', target: '8', targetPosition: 'right' }, // Hash Map/Set to Queue (right)
  { id: 'e6-12', source: '6', target: '12', targetPosition: 'right' }, // Hash Map/Set to Heap (right)
  { id: 'e6-9', source: '6', target: '9', targetPosition: 'bottom' }, // Hash Map/Set to LinkedList (bottom)
  { id: 'e9-10', source: '9', target: '10', targetPosition: 'bottom' }, // Linked List to Tree (bottom)
  { id: 'e8-10', source: '8', target: '10', targetPosition: 'left' }, // Queue to Tree (left)
  { id: 'e10-11', source: '10', target: '11', targetPosition: 'bottom' }, // Tree to Graph (bottom)
  { id: 'e3-13', source: '3', target: '13', targetPosition: 'right' }, // Two Pointer to Binary Search (right)
  { id: 'e10-14', source: '10', target: '14', targetPosition: 'bottom' }, // Tree to Backtracking (bottom)
  { id: 'e14-15', source: '14', target: '15', targetPosition: 'bottom' }, // Backtracking to DP - 1D (bottom)
  { id: 'e14-16', source: '14', target: '16', targetPosition: 'right' }, // Backtracking to DP - Multidimensional (right)
  { id: 'e7-20', source: '7', target: '20', targetPosition: 'right' }, // Stack to Monotonic Stack (right)
  { id: 'e21-17', source: '21', target: '17', targetPosition: 'bottom' }, // Math to Bit Manipulation (bottom)
  { id: 'e13-18', source: '13', target: '18', targetPosition: 'bottom' }, // Binary Search to Trie (bottom)
  { id: 'e20-21', source: '20', target: '21', targetPosition: 'bottom' }, // Monotonic Stack to Mathematics (bottom)
  { id: 'e21-22', source: '21', target: '22', targetPosition: 'bottom' }, // Mathematics to Matrix (bottom)
];

// export const initialEdges = [
//   { id: 'e1-2', source: '1', target: '2', targetPosition: 'bottom' }, // Array to String
//   { id: 'e1-3', source: '1', target: '3', targetPosition: 'bottom' }, // Array to Binary
//   { id: 'e2-4', source: '2', target: '4', targetPosition: 'bottom' }, // String to Linked List
//   { id: 'e2-5', source: '2', target: '5', targetPosition: 'bottom' }, // String to Tree
//   { id: 'e3-5', source: '3', target: '5', targetPosition: 'bottom' }, // Binary to Tree
//   { id: 'e3-6', source: '3', target: '6', targetPosition: 'bottom' }, // Binary to Heap
//   { id: 'e5-7', source: '5', target: '7', targetPosition: 'bottom' }, // Tree to Graph
//   { id: 'e5-8', source: '5', target: '8', targetPosition: 'bottom' }, // Tree to Interval
//   { id: 'e5-9', source: '5', target: '9', targetPosition: 'bottom' }, // Tree to Matrix
//   { id: 'e6-10', source: '6', target: '10', targetPosition: 'bottom' }, // Heap to Dynamic Programming
// ];
