import { ScrollArea } from '../../components/ui/scroll-area';
import React from 'react';
import Footer from './Footer';

function Return({ isStandalone }) {
  return (
    <ScrollArea className={`${isStandalone ? 'h-screen' : 'h-full'}`}>
      <div className="p-2 md:px-[150px] pt-10 w-full">
        <h1 className="text-5xl md:text-9xl font-bold md:-ml-2">
          Return & Refund Policy
        </h1>
        <p className="text-sm pb-4 font-bold py-4">
          Last updated: July 08, 2024
        </p>
        <p className="py-4">
          Thank you for your purchase. We hope you are happy with your purchase.
          However, if you are not completely satisfied with your purchase for
          any reason, you may return it to us for. Please see below for more
          information on our return policy.
        </p>
        <div className="py-4">
          <strong className="text-3xl font-bold">RETURNS</strong>
        </div>
        <p className="py-4">
          All returns must be postmarked within days of the purchase date. All
          returned items must be in new and unused condition, with all original
          tags and labels attached.
        </p>
        <div className="py-4">
          <strong className="text-3xl font-bold">RETURN PROCESS</strong>
        </div>
        <p className="py-4">
          To return an item, please email customer service at to obtain a Return
          Merchandise Authorisation (RMA) number. After receiving a RMA number,
          place the item securely in its original packaging mail your return to
          the following address:
        </p>
        <p className="py-4">
          Mahavir Marg,
          <br />
          House No. 1,
          <br />
          Near Jain Temple,
          <br />
          Sainthia, West Bengal,
          <br />
          Pin 731234 <br />
          India <br />
        </p>
        <p>Return shipping charges will be paid or reimbursed by us. </p>
        <div className="py-4">
          <strong className="text-3xl font-bold">REFUNDS</strong>
        </div>
        <p className="py-4">
          After receiving your return and inspecting the condition of your item,
          we will process your return. Please allow at least seven (7) days from
          the receipt of your item to process your return. We will notify you by
          email when your return has been processed.
        </p>
        <div className="py-4">
          <strong className="text-3xl font-bold">EXCEPTIONS</strong>
        </div>
        <p className="py-4">
          For defective or damaged products, please contact us at the contact
          details below to arrange a refund or exchange.
        </p>
        <h2>Please Note</h2>
        <p className="py-4">
          Sale items are FINAL SALE and cannot be returned.
        </p>
        <div className="py-4">
          <strong className="text-3xl font-bold">QUESTIONS</strong>
        </div>
        <p className="py-4">
          If you have any questions concerning our return policy, please contact
          us at:
        </p>
        <p className="py-1 text-md font-semibold">7407473804</p>
        <p className="text-md font-semibold">parakh.vinit2002@gmail.com</p>
      </div>
      <Footer />
    </ScrollArea>
  );
}

export default Return;
