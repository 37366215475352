import React from 'react';
import img5 from '../../assets/comapnies/5.webp';
import img6 from '../../assets/comapnies/6.webp';
import img7 from '../../assets/comapnies/7.webp';
import img8 from '../../assets/comapnies/8.webp';
import img9 from '../../assets/comapnies/9.webp';
import { Rocket, Sailboat, Puzzle, Star, Heart, Share } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Array of images
const images = [img5, img6, img7, img8, img9];

function ProjectCard({ card }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dev/${card.title}?id=${card._id}`, { state: { card } });
  };

  const backgroundImage = images[Math.floor(Math.random() * images.length)];

  return (
    <button
      onClick={handleClick}
      className="relative w-full rounded-2xl h-[250px] bg-cover bg-center text-left overflow-hidden shadow-glass shadow-primary/5 hover:shadow-2xl transition-shadow duration-300"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        border: '2px solid rgba(255, 255, 255, 0.3)',
        backdropFilter: 'blur(10px)',
      }}>
      <div className="absolute inset-0 bg-black/50 rounded-2xl p-4 flex flex-col justify-end z-10">
        <h2 className="text-white text-3xl font-semibold mb-2 pb-1">
          {card.title}
        </h2>
        <div className="flex items-center space-x-4 py-1">
          <Rocket className="text-white w-5 h-5" />
          <Sailboat className="text-white w-5 h-5" />
          <Puzzle className="text-white w-5 h-5" />
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-t from-muted/30 via-transparent to-transparent rounded-2xl"></div>
      <div className="absolute top-4 right-4 z-10 flex space-x-2">
        <button className="p-2 rounded-full bg-white/20 hover:bg-white/40 transition">
          <Star className="text-white w-6 h-6" />
        </button>
        <button className="p-2 rounded-full bg-white/20 hover:bg-white/40 transition">
          <Heart className="text-white w-6 h-6" />
        </button>
        <button className="p-2 rounded-full bg-white/20 hover:bg-white/40 transition">
          <Share className="text-white w-6 h-6" />
        </button>
      </div>
    </button>
  );
}

export default ProjectCard;
