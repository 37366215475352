import React, { useState, useEffect, useCallback } from 'react';
import Axios from '../../services/axios';
import { Link } from 'react-router-dom';
import { buttonVariants } from '../ui/button';
import CourseCard from '../CourseCard';
import { useUser } from '@clerk/clerk-react';

function RecentlyViewed() {
  const [data, setData] = useState([]);
  const { user } = useUser();

  const getCourses = useCallback(async () => {
    try {
      const res = await Axios.get(`/course/recent/${user.id}`);
      setData(res.data.data.recentlyViewed);
    } catch (error) {
      console.log(error);
    }
  }, [user.id]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  if (data.length < 1) return <></>;

  return (
    <div className="px-1">
      <div className="flex items-center justify-between py-2">
        <h2 className="font-bold text-lg">Recently Viewed</h2>
        <Link
          to="/courses"
          state={{ data }}
          className={buttonVariants({ variant: 'outline' })}>
          View all
        </Link>
      </div>
      {data.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {data?.map(
            (course, index) =>
              ((window.innerWidth < 768 && index < 2) || // for small screens
                (window.innerWidth >= 768 &&
                  window.innerWidth < 1024 &&
                  index < 3) || // for medium screens
                (window.innerWidth >= 1024 && index < 4)) && (
                <CourseCard
                  key={index}
                  course={course.course}
                  viewed={course.viewedAt}
                />
              )
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(RecentlyViewed);
