import React from 'react';
import { Header, Toggle, Cards } from '../../components/dev';
import { ScrollArea } from '../../components/ui/scroll-area';

function Dev() {
  return (
    <ScrollArea className="p-1 sm:px-8 h-full">
      <Header />
      <Toggle />
      <Cards />
    </ScrollArea>
  );
}

export default Dev;
