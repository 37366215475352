import React from 'react';
import {
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '../../components/ui/sheet';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/ui/table';
import { Checkbox } from '../../components/ui/checkbox';
import { ScrollArea } from '../../components/ui/scroll-area';
import { questions } from '../../assets/js/DSAsheet';
import { Youtube, Github } from 'lucide-react';

const Top75LeetCodeQuestions = ({ category }) => {
  console.log(questions[category], category);
  const renderList = () => {
    const filteredProblems = questions[category];

    return filteredProblems?.map((p, i) => (
      <TableRow key={i}>
        <TableCell>
          <Checkbox />
        </TableCell>
        <TableCell>
          <a href={p.link} target="_blank" rel="noopener noreferrer">
            {p.qs}
          </a>
        </TableCell>
        <TableCell>{category}</TableCell>
        <TableCell
          className={`${
            p?.difficulty === 'Easy'
              ? 'text-green-500'
              : p?.difficulty === 'Medium'
              ? 'text-yellow-600'
              : p?.difficulty === 'Hard'
              ? 'text-red-500'
              : ''
          }`}>
          {p.difficulty}
        </TableCell>

        <TableCell>
          <a href={p?.ytvid} target="_blank" rel="noopener noreferrer">
            <Youtube color="red" size={24} />
          </a>
        </TableCell>
        <TableCell>
          <a href={p?.Git} target="_blank" rel="noopener noreferrer">
            <Github color="purple" size={24} />
          </a>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <SheetContent side={'bottom'} className="h-[600px] ml-16">
      <SheetHeader>
        <SheetTitle>Hand picked Leetcode Questions</SheetTitle>
        <SheetDescription>
          List of some of the top LeetCode questions of {category}.
        </SheetDescription>
      </SheetHeader>
      <ScrollArea className="h-full mt-4 flex-1">
        <Table>
          <TableCaption>A list of popular LeetCode questions</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead>Attempted</TableHead>
              <TableHead>Problem</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Difficulty</TableHead>
              <TableHead>Solution</TableHead>
              <TableHead>Code</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>{renderList()}</TableBody>
        </Table>
      </ScrollArea>
    </SheetContent>
  );
};

export default Top75LeetCodeQuestions;
