import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import {
  Heart,
  Bookmark,
  Share2,
  BookmarkCheck,
  Award,
  Instagram,
  Linkedin,
  X,
  Share,
} from 'lucide-react';
import { Label } from '../ui/label';
import { timeAgo, axios } from '../../services';
import { Button } from '../ui/button';
import { useUser } from '@clerk/clerk-react';
import { ScrollArea } from '../ui/scroll-area';
import MarkDownView from '../MarkDownView';
import { Comments, AvatarComponent } from './';
import { useCommentContext } from '../../context/useComments';

function Blog() {
  const { story } = useCommentContext();
  const { user } = useUser();

  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const url = window.location.href;

  const handleLike = async () => {
    try {
      const newLikedStatus = !liked;
      await axios.patch(`/placement/${user.id}/${story._id}`);
      setLiked(newLikedStatus);
      setLikesCount((prevCount) => prevCount + (newLikedStatus ? 1 : -1));
    } catch (error) {
      console.log('error updating like: ', error);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Brogrammers blog',
          text: 'hello',
          url: url,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  const handleBookmark = async () => {
    try {
      await axios.patch(`/user/bookmark/${user.id}/${story._id}`);
    } catch (error) {
      console.log('error updating bookmark: ', error);
    }
  };

  useEffect(() => {
    if (story) {
      setLiked(story?.likes?.some((like) => like?.userId === user.id));
      setLikesCount(story?.likes?.length);
    }
  }, [story, user.id]);

  return (
    <Card className="mt-2 bg-muted/30 relative shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row h-full">
      <ScrollArea className="w-full md:w-7/12">
        <Button
          variant="outline"
          onClick={handleShare}
          className="absolute top-4 right-4">
          <Share2 className="hover:text-gray-500 cursor-pointer" />
        </Button>

        <CardHeader className="flex flex-row justify-start items-center gap-4 p-4 border-b bg-muted/40 hover:bg-muted rounded-md transition-colors">
          <AvatarComponent imageUrl={story?.userId?.imageUrl} />
          <div className="flex-1">
            <CardTitle className="text-lg md:text-xl font-semibold flex items-center gap-2">
              <span>{story?.studentName}</span>
              <Award className="text-brand" />
              <span>{story?.package} LPA</span>
            </CardTitle>
            <CardDescription className="text-sm text-primary mt-1">
              {story?.companyName} - {story?.jobRole}
            </CardDescription>
          </div>
        </CardHeader>

        <CardContent className="p-6 flex-grow pb-2">
          <div className="text-md text-balance text-primary">
            <MarkDownView>{story?.markdown}</MarkDownView>
          </div>
          <div className="flex gap-4 mb-2 md:mb-0">
            <h2>Folllow me here: </h2>
            {story?.linkedIn && (
              <a
                href={story?.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800">
                <Linkedin className="w-6 h-6" />
              </a>
            )}
            {story?.x && (
              <a
                href={story?.x}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-600">
                <X className="w-6 h-6" />
              </a>
            )}
            {story?.instagram && (
              <a
                href={story?.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink-500 hover:text-pink-700">
                <Instagram className="w-6 h-6" />
              </a>
            )}
          </div>
        </CardContent>

        <CardFooter className="p-4 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center mt-auto">
          <div className="flex items-center justify-evenly gap-2 mb-2 md:mb-0">
            <Button
              variant="outline"
              className={`flex items-center ${
                liked
                  ? 'text-pink-600 border-pink-600'
                  : 'text-gray-500 border-gray-500'
              } hover:text-green-500 hover:border-green-500 hover:bg-green-900`}
              onClick={handleLike}>
              <Heart
                size="16"
                className={`${
                  liked
                    ? 'fill-current text-pink-600'
                    : 'fill-current text-gray-500'
                }`}
              />
              <Label className="text-lg font-bold ml-1">{likesCount}</Label>
            </Button>
            <Button variant="outline" onClick={handleBookmark}>
              <Bookmark className="hover:text-gray-500 cursor-pointer" />
            </Button>
            <Button variant="outline" onClick={handleShare}>
              <Share2 className="hover:text-gray-500 cursor-pointer" />
            </Button>
          </div>

          <CardDescription className="text-xs text-muted-foreground">
            Uploaded: {timeAgo(story?.submissionDate)}
          </CardDescription>
        </CardFooter>
      </ScrollArea>
      <Comments />
    </Card>
  );
}

export default Blog;
