import React, { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import MarkDownView from '../MarkDownView';
import { Button } from '../ui/button';
import { useCourse } from '../../context/useCourseContext';
import { Link } from 'react-router-dom';

function Summary() {
  const { isBro, course, getKeynotes } = useCourse();
  const [loading, setLoading] = useState(false);
  return (
    <div className="h-full pb-20">
      {course?.keyNotes?.length > 0 ? (
        course?.keyNotes?.map((notes, index) => (
          <Accordion key={index} type="single" collapsible>
            <AccordionItem value={notes?._id}>
              <AccordionTrigger>{notes.title}</AccordionTrigger>
              <AccordionContent>
                <MarkDownView>{notes.content}</MarkDownView>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))
      ) : (
        <div className="flex items-center justify-center rounded-lg border border-dashed shadow-sm p-2 sm:p-20">
          <div className="flex flex-col items-center gap-1 text-center h-full">
            <h3 className="text-2xl font-bold tracking-tight">
              Glossary / Revision notes
              <div className="h-1 mx-auto bg-gray-200 w-28 opacity-75 my-4 rounded"></div>
            </h3>
            <div className="flex flex-col items-center justify-center">
              <p className="text-sm text-muted-foreground text-left">
                Generate a comprehensive glossary to enhance your learning
                experience. It includes:
                <ul className="list-disc list-inside mt-2">
                  <li>Important definitions and formulas</li>
                  <li>Essential concepts</li>
                  <li>Key topics to remember</li>
                  <li>Important questions</li>
                  <li>Critical dates and events</li>
                  <li>Notable figures and their contributions</li>
                </ul>
              </p>
            </div>
            {isBro ? (
              <Button
                onClick={() => {
                  setLoading(true);
                  getKeynotes(course._id, course.title);
                }}
                disabled={loading}
                className="my-2">
                Generate
              </Button>
            ) : (
              <div className="text-center mt-4">
                <p className="mb-2 text-muted-foreground">
                  Upgrade to premium for exclusive features and tools.
                </p>
                <Link to={'/pricing'}>
                  <Button size="sm">
                    <Link to={'/pricing'}>Get Prime</Link>
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Summary;
