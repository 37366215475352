import React from 'react';
import { CardContent, CardHeader, CardTitle } from '../ui/card';
import { Label } from '../ui/label';
import { Textarea } from '../ui/textarea';

const Step5 = ({ formData, handleChange }) => (
  <div>
    <CardHeader>
      <CardTitle className="text-2xl font-semibold mb-4">
        Personal Experience
      </CardTitle>
    </CardHeader>

    <CardContent>
      <Textarea
        name="personalExperience"
        placeholder="Your experience, conditions evrything in detail. How was the pressure, enviroment anything."
        value={formData.personalExperience}
        onChange={handleChange}
        required
        className="p-4 m-2 text-md"
      />
    </CardContent>
  </div>
);

export default Step5;
