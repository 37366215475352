import React from 'react';
import { CardContent, CardHeader, CardTitle } from '../ui/card';
import { Input } from '../ui/input';
import { Label } from '../ui/label';

const Step2 = ({ formData, handleChange }) => (
  <div>
    <CardHeader>
      <CardTitle className="text-2xl font-semibold mb-4">Job Details</CardTitle>
    </CardHeader>

    <CardContent>
      <Label>Company Name & Location</Label>
      <Input
        type="text"
        placeholder="Cred Bengaluru onsite"
        name="companyName"
        value={formData.companyName}
        onChange={handleChange}
        required
      />
      <Label>Job Role</Label>
      <Input
        type="text"
        placeholder="SDE Intern"
        name="jobRole"
        value={formData.jobRole}
        onChange={handleChange}
        required
      />
      <Label>Package</Label>
      <Input
        type="number"
        placeholder="6 LPA"
        name="package"
        value={formData.package}
        onChange={handleChange}
        required
      />
    </CardContent>
  </div>
);

export default Step2;
